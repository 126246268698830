import { filesize, convertUnits, getFormatPrice, sortItems, currencies, renewalPeriods } from '@/elements/utils.js';
import { fetchPurchaseMetadata, getPackagesForUpgrade, countPrice } from '@/api';
import EventBus from '@/elements/eventBus.js';
import gettext from '@/utils/translationInjector.js';
const { $gettext, $ngettext } = gettext;

const getInitialState = () => ({
  currency: '',
  initialCurrency: '',
  renewalPeriod: '',
  currentPackageId: null,
  newMainPackageId: null,
  isTrial: null,
  packages: [],
  customPackages: [],
  packagesForUpgrade: [],
  packagesFromPurchase: [],
  pricing: {
    '1 mon': [],
    '1 year': [],
  }, // prices for full year by renewal_periods
  upgradePricing: [], // prices for upgrade period
  currentPeriodPrice: null, // price for current period upgrade
  secure3DCode: null,
  isChangesToSave: false,
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    getItemLabel: () => (key, label) => {
      const labels = {
        max_users: $gettext('User licences'),
        disc_quota: $gettext('Storage'),
      };
      return labels[key] || label;
    },
    getItemType: () => (count, type) => {
      const item_types = {
        max_users: $ngettext('user', 'users', count),
        disc_quota: 'GB',
        file_automation: $ngettext('licence', 'licences', count),
      };
      return item_types[type];
    },
    getItemUnits: (state) => (value, name) => {
      const isBytes = ['disc_quota'].includes(name);
      return isBytes ? filesize(convertUnits(value, 'GB', 'B')) : value;
    },
    getPrice:
      (state) =>
      (id, item_id, type, renewal_period = state.renewalPeriod) => {
        let prices;
        switch (type) {
          case 'upgrade':
            prices = state.upgradePricing;
            break;
          default:
            prices = state.pricing[renewal_period] || [];
        }
        return prices.find((price) => {
          return (
            (id ? price.package_id === id : !Boolean(price.package_id)) &&
            (item_id ? price.package_item_id === item_id : !Boolean(price.package_item_id))
          );
        });
      },
    getFormattedPrice: (state) => (price) => {
      if (typeof price === 'number') {
        return currencies[state.currency] + getFormatPrice(price);
      } else {
        return null;
      }
    },
    getItemPrice:
      (state, getters) =>
      (id, item_id, formatted = true, type = 'new', renewal_period = state.renewalPeriod) => {
        const pack = getters.getPrice(id, item_id, type, renewal_period);
        const price = pack ? pack.price[state.currency].total_period_price : 0;
        return formatted ? getters.getFormattedPrice(price) : price;
      },
    getItemDiscount:
      (state, getters) =>
      (id, item_id, type = 'new', renewal_period = state.renewalPeriod) => {
        const pack = getters.getPrice(id, item_id, type, renewal_period);
        const price = pack ? pack.price[state.currency].total_discount : 0;
        return price;
      },
    getPricePerItem:
      (state, getters) =>
      (id, item_id, item_type, formatted = true, type = 'new', renewal_period = state.renewalPeriod) => {
        const pack = getters.getPrice(id, item_id, type, renewal_period);
        const price = pack ? pack.price[state.currency].price : 0;
        if (!price) {
          return null;
        }
        return formatted ? `${getters.getFormattedPrice(price)}/${getters.getItemType(1, item_type)}` : price;
      },
    getItemValue: (state, getters) => (id, item_id) => {
      const pack = getters.getPrice(id, item_id);
      if (pack) {
        return pack.value;
      }
    },
    getItemTotalValue: (state, getters) => (id, item_id, item_type) => {
      const pack = getters.getPrice(id, item_id);

      if (pack) {
        return `${pack.total_value} ${item_type ? getters.getItemType(pack.total_value, item_type) : ''}`;
      }
    },
    getItemNewValue: (state) => (package_id, item_id, item_type) => {
      const newMainPackage = state.packagesForUpgrade?.find((pack) => pack.id === state.newMainPackageId);
      const targetPackage =
        item_type === 'max_users' ? newMainPackage : newMainPackage?.children?.find((pack) => pack.id === package_id);

      if (targetPackage) {
        const targetItem = targetPackage.items?.find((item) => item.id === item_id);
        return targetItem?.value;
      }
    },
    getItemDescription: (state, getters) => (package_id, item_id, item_type) => {
      const quantity = getters.getItemNewValue(package_id, item_id, item_type);
      const type = getters.getItemType(quantity, item_type);
      const priceMultiplier = getters.getPricePerItem(package_id, item_id, item_type);
      const renewalPeriod =
        state.renewalPeriod === renewalPeriods.MONTH_PERIOD ? $gettext('1 month') : $gettext('12 months');
      return `${quantity} ${type} x ${priceMultiplier} x ${renewalPeriod}`;
    },
    getPackageStructure: (state) => (pack) => {
      const result = [];
      const getPack = (pack) => {
        let res = { id: pack.id };
        if (pack.items?.length > 0) {
          res.items = pack.items.map((item) => {
            return {
              id: item.id,
              value: item.value ? parseInt(item.value, 10) : item.value,
            };
          });
        }
        result.push(res);
        if (pack.children?.length > 0) {
          pack.children
            .filter((child) => child.isActive)
            .forEach((child) => {
              getPack(child);
            });
        }
      };
      getPack(pack);
      return result;
    },
    getPackDescription: (state) => (text, columns) => {
      if (text) {
        const desc = text.split(', ');
        if (desc.length) {
          const itemsInOneCol = Math.round(desc.length / columns);
          const res = [];
          while (desc.length) {
            res.push(desc.splice(0, itemsInOneCol));
          }
          return res;
        }
      } else {
        return [];
      }
    },
    getPackPeriodPrice:
      (state, getters) =>
      (pack, type = 'new', renewal_period = state.renewalPeriod) => {
        let price = 0;
        price += getters.getItemPrice(pack.id, undefined, false, type, renewal_period);
        if (pack.items?.length > 0) {
          pack.items.forEach((item) => {
            price += getters.getItemPrice(pack.id, item.id, false, type, renewal_period);
          });
        }
        if (pack.children?.length > 0) {
          pack.children
            .filter((child) => child.isActive)
            .forEach((child) => {
              price += getters.getPackPeriodPrice(child, type, renewal_period);
            });
        }
        return price;
      },
    getTotalPeriodPrice:
      (state, getters) =>
      (pack, type = 'new', renewal_period = state.renewalPeriod) => {
        let price = 0;
        if (pack?.id) {
          price += getters.getPackPeriodPrice(pack, type, renewal_period);
        }
        if (state.packagesFromPurchase?.length > 0) {
          state.packagesFromPurchase.forEach((pack) => {
            price += getters.getPackPeriodPrice(pack, type, renewal_period);
          });
        }
        return getters.getFormattedPrice(price);
      },
  },
  mutations: {
    setSecure3DCode(state, code) {
      state.secure3DCode = code;
    },
    setSecurityModule(state, value) {
      const newMainPackage = state.packagesForUpgrade?.find((pack) => pack.id === state.newMainPackageId);
      const moduleIndex = newMainPackage?.children?.findIndex((child) => child.key === 'ESM');

      if (moduleIndex >= 0 && moduleIndex < newMainPackage?.children.length) {
        newMainPackage.children.splice(moduleIndex, 1, value);
      }
    },
    setUserLicencesValue(state, value) {
      const newMainPackage = state.packagesForUpgrade?.find((pack) => pack.id === state.newMainPackageId);
      const licences = newMainPackage?.items?.find((item) => item.name === 'max_users');

      if (licences) {
        licences.value = value;
      }
    },
    setPackages(state, payload) {
      const packages = sortItems(payload.packages, ['order_num']);
      packages.map((pack) => {
        if (pack.items?.length > 0) {
          pack.items = sortItems(pack.items, ['order_num']);
        }
      });
      const mainPack = packages.find((pack) => pack.id === payload.main_id);
      if (mainPack) {
        const mainPackIndex = packages.indexOf(mainPack);
        packages.splice(mainPackIndex, 1);
        state.packages = [mainPack].concat(packages);
      } else {
        state.packages = packages;
      }
    },
    setCustomPackages(state, packages) {
      state.customPackages = packages;
    },
    setPackagesForUpgrade(state, packages) {
      state.packagesForUpgrade = packages;
    },
    setPackagesFromPurchase(state, packages) {
      state.packagesFromPurchase = packages;
    },
    setCurrency(state, currency) {
      state.currency = currency;
    },
    setInitialCurrency(state, currency) {
      state.initialCurrency = currency;
    },
    setRenewalPeriod(state, renewalPeriod) {
      state.renewalPeriod = renewalPeriod;
    },
    setCurrentPackageId(state, id) {
      state.currentPackageId = id;
    },
    setNewMainPackageId(state, id) {
      state.newMainPackageId = id;
    },
    setIsTrial(state, is_trial) {
      state.isTrial = is_trial;
    },
    setPackagePricing(state, payload) {
      const currentRenewalPricing = state.pricing[payload.renewal_period];
      const isNoPricing = currentRenewalPricing?.length === 0;
      const isDifferentMainPackage =
        !isNoPricing && !currentRenewalPricing?.map((pack) => pack.package_id).includes(payload.main_package_id);

      if (isNoPricing || isDifferentMainPackage) {
        state.pricing = { ...state.pricing, [payload.renewal_period]: payload.pricing };
      } else {
        const updateTargetPrice = (findFn) => {
          const targetPack = currentRenewalPricing.find(findFn);
          const updatedPack = payload.pricing.find(findFn);

          targetPack.price = updatedPack.price;
        };

        payload.packages.forEach((pack) => {
          updateTargetPrice((target) => target.package_id === pack.id && target.package_item_id.length === 0);
          if (pack.items?.length > 0) {
            pack.items.forEach((item) => {
              updateTargetPrice((target) => target.package_item_id === item.id);
            });
          }
        });
      }
    },
    setPackageUpgradePricing(state, pricing) {
      state.upgradePricing = pricing;
    },
    setCurrentPeriodPrice(state, price) {
      state.currentPeriodPrice = price < 0 ? 0 : price; // prices lower than zero interpret as zero
    },
    setIsChangesToSave(state, status) {
      state.isChangesToSave = status;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getPurchaseMetadata({ state, commit, dispatch }) {
      try {
        const { data } = await fetchPurchaseMetadata();
        commit('setCurrency', data.currency);
        commit('setInitialCurrency', data.currency);
        commit('setRenewalPeriod', data.renewal_period);
        commit('setPackages', { packages: data.packages, main_id: data.main_package_id });
        commit('setIsTrial', state.packages[0].is_trial);
        commit('setCustomPackages', data.custom_packages);
        commit('setCurrentPackageId', data.main_package_id);
        commit('setNewMainPackageId', data.main_package_id);
        await dispatch('getPackagesForUpgrade');
        return Promise.resolve({
          canUpgrade: state.isTrial || data.expiration_date * 1000 > new Date().getTime(),
        });
      } catch (error) {
        if (error && error.response) {
          // EventBus.$emit('handle-error', { response: error.response });
        }
        return Promise.reject(error);
      }
    },
    async getPackagesForUpgrade({ state, commit }, options = {}) {
      const changePackage = (item) => {
        if (item.items) {
          item.items = sortItems(item.items, ['order_num']);
        }
        if (item.children) {
          item.children.forEach((child) => {
            child.isActive = true;
            changePackage(child);
          });
        }
      };
      const setPurchaseValues = (pack) => {
        const purchasePack = state.packages.find((purchase) => purchase.id === pack.id);
        if (purchasePack) {
          if (pack.items?.length > 0) {
            pack.items.forEach((item) => {
              if (item.customizable) {
                const purchaseItem = purchasePack.items.find((pitem) => pitem.id === item.id);
                if (purchaseItem) {
                  item.value = purchaseItem.value;
                }
              }
            });
          }
          if (pack.children?.length > 0) {
            pack.children.forEach((child) => setPurchaseValues(child));
          }
        }
      };
      const getPackagesFromPurchase = (mainPack) => {
        const getMainPackageFlat = () => {
          let result = [];
          const getPack = (pack) => {
            result.push(pack.id);
            if (pack.children?.length > 0) {
              pack.children.forEach((child) => {
                getPack(child);
              });
            }
          };
          getPack(mainPack);
          return result;
        };
        const flatPacks = getMainPackageFlat();
        const flatPurchase = state.packages.map((pack) => pack.id);
        const difference = flatPurchase.filter((pack) => !flatPacks.includes(pack));
        if (difference.length) {
          commit(
            'setPackagesFromPurchase',
            state.packages.filter((pack) => difference.includes(pack.id))
          );
        }
      };
      try {
        const response = await getPackagesForUpgrade();
        const sortedData = response?.data?.length > 0 ? sortItems(response.data, ['order_num']) : [];
        if (sortedData.length > 0) {
          sortedData.forEach((item) => changePackage(item));

          const mainPackage = sortedData.find((pack) => pack.id === state.currentPackageId);

          if (mainPackage) {
            setPurchaseValues(mainPackage);
            getPackagesFromPurchase(mainPackage);
          }
        }
        commit('setPackagesForUpgrade', sortedData);
        return Promise.resolve();
      } catch (error) {
        if (error && error.response) {
          EventBus.$emit('handle-error', { response: error.response });
        }
        return Promise.reject(error);
      }
    },
    async getPrice({ state, commit }, options = {}) {
      const body = {
        main_package_id: options.main_package_id,
        packages: options.packages,
        ...(options.currency && { currency: options.currency }),
        ...(options.renewal_period && { renewal_period: options.renewal_period }),
      };
      try {
        const { data } = await countPrice(body);
        commit('setPackagePricing', {
          main_package_id: options.main_package_id,
          packages: options.packages,
          pricing: data.new_price,
          renewal_period: options.renewal_period || state.renewalPeriod,
        });
        if (!options.silent) {
          commit('setPackageUpgradePricing', data.upgrade_price);
        }
        return Promise.resolve();
      } catch (error) {
        if (error && error.response) {
          EventBus.$emit('handle-error', { response: error.response });
        }
        return Promise.reject(error);
      }
    },
  },
};
