import gettext from '@/utils/translationInjector.js';
const { $gettext } = gettext;

export default {
  namespaced: true,
  state: {},
  getters: {
    getStatus: (state) => (model) => {
      const status = model.isActive;
      const expires = model.expire_date * 1000;
      const activates = model.release_date * 1000;
      const now = new Date().getTime();
      if (status) {
        if (now > expires && expires !== 0) {
          return {
            id: 'expired',
            text: $gettext('Expired'),
          };
        }
        if (now < activates) {
          return {
            id: 'not-released',
            text: $gettext('Not released'),
          };
        }
        return {
          id: 'active',
          text: $gettext('Active'),
        };
      }
      return {
        id: 'revoked',
        text: $gettext('Revoked'),
      };
    },
    isRevocable: () => (activity) => activity.isActive,
  },
  mutations: {},
  actions: {},
};
