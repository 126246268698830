<template>
  <div
    v-if="isIE"
    class="message-box">
    <img
      src="@/assets/images/internet_explorer_logo.svg"
      :alt="string.ieLogo"
      class="mr-3">
    <p>{{ string.ieMessage }}</p>
  </div>
</template>

<script>
export default {
  name: 'BrowserNoSupportMessage',
  computed: {
    isIE() {
      const ua = navigator.userAgent;
      return ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1;
    },
    string() {
      return {
        ieMessage: this.$gettext(
          'We’re sorry. We are not going to support Internet Explorer. You can access Quatrix from one of the following supported browsers: Google Chrome, Mozilla Firefox, Apple Safari, or Microsoft Edge.'
        ),
        ieLogo: this.$gettext('Internet Explorer logo'),
      };
    },
  },
  mounted() {
    if (this.isIE) {
      document.body.classList.add('ie-no-support');
    }
  },
};
</script>

<style lang="scss" scoped>
.message-box {
  background: $y-25;
  border: 1px solid $y-10;
  top: 10px;
  padding: 8px 10px 8px 18px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  z-index: 100;

  p {
    margin: 0;
    padding: 0;
  }
}
</style>
