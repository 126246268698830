import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';

import axios from 'axios';

const MIN_SENTRY_TIMEOUT = 1000;
const MAX_SENTRY_TIMEOUT = parseInt(process.env.VUE_APP_SENTRY_RETRY_TIMEOUT, 10) || 60000;

class QuatrixTransport extends Sentry.Transports.BaseTransport {
  constructor(props) {
    super(props);

    try {
      this.events = (JSON.parse(localStorage.getItem('sentryEvents')) || []).filter(Boolean);
    } catch {
      this.events = [];
    } finally {
      window.addEventListener('beforeunload', () => {
        const eventsToStore = [...new Set(this.events.filter(Boolean))];
        if (eventsToStore.length) {
          localStorage.setItem('sentryEvents', JSON.stringify(eventsToStore));
        } else {
          localStorage.removeItem('sentryEvents');
        }
      });

      this.startTimeout = MIN_SENTRY_TIMEOUT;
      this.retryInterval = null;

      if (this.events.length) {
        this.sendEvent();
      }
    }
  }
  sendEvent(event) {
    if (event) {
      if (!this.events.some((el) => el.event_id === event.event_id)) {
        this.events.push(event);
      }
    }

    if (!this.events.length) {
      return;
    }

    return axios
      .post(this.url, this.events[0])
      .then(() => {
        this.startTimeout = MIN_SENTRY_TIMEOUT;

        if (this.retryInterval) {
          clearInterval(this.retryInterval);
          this.retryInterval = null;
        }

        this.events.shift();
        if (this.events.length) {
          this.sendEvent();
        }
      })
      .catch(() => {
        if (this.retryInterval) {
          clearInterval(this.retryInterval);
          this.retryInterval = null;
        }

        this.startTimeout = Math.min(this.startTimeout * 2, MAX_SENTRY_TIMEOUT);
        this.retryInterval = setTimeout(() => {
          this.sendEvent();
        }, this.startTimeout);
      });
  }
}
if (process.env.VUE_APP_SENTRY_KEY) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_KEY,
    whitelistUrls: process.env.VUE_APP_SENTRY_WHITELIST_URLS
      ? process.env.VUE_APP_SENTRY_WHITELIST_URLS.split(',')
      : [],
    collectWindowErrors: true,
    integrations: [
      //TODO: vue 3 fix Integrations
      // new Integrations.Vue({ Vue, logErrors: true }),
      new Sentry.Integrations.GlobalHandlers({
        onerror: true,
        onunhandledrejection: false,
      }),
      new Sentry.Integrations.Breadcrumbs({
        // XMLHttpRequest
        xhr: true,
        // console logging
        console: false,
        // DOM interactions, i.e. clicks/typingevents
        dom: true,
        // url changes, including pushState/popState
        history: true,
      }),
    ],
    transport: QuatrixTransport,
  });
  if (process.env.VUE_APP_SENTRY_TAGS) {
    try {
      Sentry.configureScope((scope) => {
        scope.setTags(JSON.parse(process.env.VUE_APP_SENTRY_TAGS));
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Failed to setup tags');
    }
  }
} else {
  // eslint-disable-next-line no-console
  console.log('Missing sentry key');
}

window.Sentry = Sentry;
