import moment from 'moment';
import { MOMENT_FORMATS } from '@/utils/cons.js';

import gettext from '@/utils/translationInjector.js';
const { $gettext } = gettext;

import { getActivity } from '@/api';

const getInitialState = () => ({
  activityList: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parse: (state, getters) => (action) => {
      const act = {};
      const actionTime = moment(action.timestamp * 1000);
      const payload = action.payload;
      if (payload.response && payload.response.id) {
        act.share_id = payload.response.id;
      } else if (payload.share) {
        act.share_id = payload.share.id || payload.share;
      }
      act.id = action.id;
      act.created = action.timestamp;
      act.date = actionTime.format(MOMENT_FORMATS.DATE_FULL_TIME);
      act.event = action.event;
      act.action = action.action;
      act.protocol = action.protocol && action.protocol.toUpperCase();
      act.isActive = action.share_status === 'D' ? false : true;
      act.payload = payload;
      act.user_name = action.user_name;
      act.email = action.email;

      // Checked statuses in table
      act.isDisabledState = false;
      act.isCheckedState = false;
      act.isActiveState = false;
      return act;
    },
    getActivityById: (state) => (id) => {
      return state.activityList.find((item) => item.id === id);
    },
    isRevocable: () => (activity) => {
      return ['share', 'quicklink'].includes(activity.event) && activity.action === 'create' && activity.isActive;
    },
    getLocalDate: () => (date) => {
      return moment.utc(date).utcOffset(moment().utcOffset());
    },
    getStatus: (state, getters) => (activity) => {
      const status = activity.isActive,
        payload = activity.payload,
        expires = parseInt(getters.getLocalDate(payload.expires).format('x'), 10),
        activates = parseInt(getters.getLocalDate(payload.activates).format('x'), 10),
        now = new Date().getTime();
      if (status) {
        if (now > expires && expires !== 0) {
          return { text: $gettext('Expired') };
        }
        if (now < activates) {
          return { text: $gettext('Not released') };
        }
        return { text: $gettext('Active') };
      } else {
        return { text: $gettext('Revoked') };
      }
    },
  },
  mutations: {
    clearActivityList(state) {
      state.activityList = [];
    },
    setActivityList(state, activities) {
      activities.forEach((activity) => {
        // filter duplicates
        if (!state.activityList.find((item) => item.id === activity.id)) {
          state.activityList.push(activity);
        }
      });
    },
    changeStatus(state, { id, status }) {
      const model = state.activityList.find((activity) => activity.id === id);
      model.isActive = status;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getActivities({ getters, commit }, options = {}) {
      const { user_id, from, to, limit, id } = options;
      const params = {
        user_id,
        from,
        to,
        limit,
        ...(id && { id }),
      };
      const { data } = await getActivity(params);
      let activities = (data || []).map((activity) => getters.parse(activity));
      commit('setActivityList', activities);
      return data;
    },
  },
};
