const BASE_URL = window.location.origin + '/api/1.0';

const _requestFunction = ({ url, headers = {}, data = {}, method = 'GET' }, callback) => {
  if (!window.jQuery) {
    throw new Error('requestFunction require jQuery');
  }
  const options = {
    url: url.includes(window.location.origin) ? url : BASE_URL + url,
    type: method,
    data,
    beforeSend: (xhr) => {
      xhr.setRequestHeader('Cache-Control', 'no-store');
      if (headers) {
        for (let key in headers) {
          if (headers.hasOwnProperty(key)) {
            xhr.setRequestHeader(key, headers[key]);
          }
        }
      }
    },
    complete: (xhr, status) => {
      callback?.(xhr, status);
    },
  };

  if (method === 'POST') {
    options.contentType = 'application/json; charset=UTF-8';
    options.data = JSON.stringify(options.data);
  }

  return window.jQuery.ajax(options);
};

const API = {
  timeout: 0,
  mqTimeout: null,
  _request(args, count = 0) {
    let counter = count;
    return _requestFunction(args, (xhr) => {
      this.onApiCall && this.onApiCall(args, xhr);

      if (xhr.status === 0 && !args.noRetry) {
        counter++;
        if (counter < 3) {
          setTimeout(() => {
            this._request(args, counter);
          }, 100);
          return;
        }
      }

      if (typeof args.callback === 'function') {
        const isJSON = /json/.test(xhr.getResponseHeader('Content-Type'));
        const response = isJSON ? JSON.parse(xhr.responseText) : xhr.responseText;

        if (xhr.status >= 400 && !args.silent) {
          this.onApiError(args, xhr, response, () => args.callback(xhr, response));
        } else {
          args.callback(xhr, response);
        }
      }
    });
  },
  onApiError(args, xhr, resp, callback) {
    return callback?.();
  },
};

API.abortMq = function () {
  if (API._mq?.state() === 'pending') {
    API._mq.abort();
  }
};

API.mqReq = function (
  {
    callback,
    callback_options,
    is_terminate_request = () => {
      return false;
    },
  },
  lastModified,
  etag
) {
  const headers = {};

  if (lastModified) {
    headers['If-Modified-Since'] = lastModified;
  }

  if (etag) {
    headers['If-None-Match'] = etag;
  }

  API._mq = _requestFunction({ url: `${window.location.origin}/mq/req`, method: 'GET', headers }, (xhr, status) => {
    // Restart itself, except when aborted (status == 'abort')
    if (xhr.status || status !== 'abort') {
      if (xhr.status == 200) {
        API.timeout = 0;
      } else {
        API.timeout += 1000;
      }
      if (is_terminate_request()) {
        if (API.mqTimeout) {
          clearTimeout(API.mqTimeout);
        }
        return;
      }
      API.mqTimeout = setTimeout(() => {
        return API.mqReq(
          { callback, callback_options },
          xhr.getResponseHeader('Last-Modified'),
          xhr.getResponseHeader('Etag')
        );
      }, API.timeout);
    }

    callback?.(callback_options, xhr);
  });
  return API._mq;
};

window.API = API;
