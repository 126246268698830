import { createApp } from 'vue';
import App from '@/App.vue';
import store from '@/store/index.js';
import router from '@/router.js';

import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import '@mdi/font/css/materialdesignicons.css';

import { createMetaManager } from 'vue-meta';
import PortalVue from 'portal-vue';
import Notifications from '@kyvg/vue3-notification';
import Vue3Sanitize from 'vue-3-sanitize';

import createGettext from '@/utils/translationInjector.js';
import '@/utils/translationInjector.js';
import '@/utils/sentryClient.js';
import '@/utils/validation/rules';

const app = createApp(App);

app.use(Notifications);
app.use(
  createVuetify({
    theme: false,
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
  })
);
app.use(PortalVue);
app.use(createMetaManager());
app.use(Vue3Sanitize);
app.use(createGettext);
app.use(store);
app.use(router);

const { $gettext, interpolate, $ngettext } = createGettext;

app.config.globalProperties.$gettext = $gettext;
app.config.globalProperties.$gettextInterpolate = interpolate;
app.config.globalProperties.$ngettext = $ngettext;

app.mount('#app');
