<template>
  <div
    v-if="id"
    :id="id"
    :class="{
      opaque: global,
      zeroPosition: zeroPosition,
    }"
    class="spinner-layout">
    <div class="spinner">
      <div class="rect1" />
      <div class="rect2" />
      <div class="rect3" />
      <div class="rect4" />
      <div class="rect5" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    id: {
      type: String,
      required: true,
    },
    global: {
      type: Boolean,
      default: false,
    },
    zeroPosition: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
/* Spinner on other pages */

#loading {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1500;
  top: 0;
  div.spinner {
    position: absolute;
    text-align: center;
    top: 35%;
    width: 100%;
    div {
      margin: 1px;
    }
  }
}

/* General spinner styles */

.zeroPosition {
  .spinner {
    margin: 0 auto;
  }
}

.spinner-layout {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1000;
  display: block;
  top: 0;
  left: 0;
  &.after-tab {
    top: 40px;
  }
  &.opaque {
    background-color: white;
  }
}

.spinner {
  margin: 100px auto;
  width: 50px;
  height: 30px;
  text-align: center;
  font-size: 10px;

  > div {
    background-color: silver;
    height: 100%;
    width: 6px;
    display: inline-block;
    font-size: 32px;
    -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
    animation: stretchdelay 1.2s infinite ease-in-out;
  }

  .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
}

@-webkit-keyframes stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
</style>
