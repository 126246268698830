const DEFAULT_TYPE = 'restricted_share';

const getInitialState = () => ({
  shareTypes: {
    public_share: 'P',
    tracked_share: 'T',
    restricted_share: 'C',
  },
  activeShareTypes: {},
  activeShareType: '', // get only using `getActiveShareType` getter
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    getActiveShareType: (state, getters) => {
      return state.activeShareType && getters.isTypeVisible(state.activeShareType)
        ? state.activeShareType
        : getters.getDefaultShareType;
    },
    getActiveShareTypeValue: (state, getters) => {
      return state.shareTypes[getters.getActiveShareType];
    },
    getShareTypeKey: (state) => (value) => {
      return Object.keys(state.shareTypes).find((type) => state.shareTypes[type] === value);
    },
    getVisibleShareTypes: (state) => {
      return Object.keys(state.activeShareTypes).filter((key) => state.activeShareTypes[key]);
    },
    getVisibleShareTypesCount: (state, getters) => {
      return getters.getVisibleShareTypes.length;
    },
    getDefaultShareType: (state, getters, rootState) => {
      const userEmail = rootState.auth.userEmail;
      if (Boolean(userEmail)) {
        const securitySettingsJson = localStorage.getItem(userEmail);
        if (Boolean(securitySettingsJson)) {
          const securitySettings = JSON.parse(securitySettingsJson);
          const securityLevel = getters.getShareTypeKey(securitySettings.security_level);
          if (securityLevel && getters.isTypeVisible(securityLevel)) {
            return securityLevel;
          }
        }
      }
      const visible = getters.getVisibleShareTypes;
      if (visible && visible.length === 1) {
        return visible[0];
      } else {
        return getters.isTypeVisible(DEFAULT_TYPE) ? DEFAULT_TYPE : visible[0];
      }
    },
    isTypeVisible: (state, getters) => (shareType) => {
      const visible = getters.getVisibleShareTypes;
      return visible.includes(shareType);
    },
    isPublicShareType: (state, getters) => {
      return getters.getActiveShareType === 'public_share';
    },
  },
  mutations: {
    setActiveShareTypes(state, shareTypes) {
      state.activeShareTypes = shareTypes;
    },
    setActiveShareType(state, key) {
      state.activeShareType = key;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
};
