import { DEFAULT_LANG, AVAILABLE_LANGS } from '@/utils/cons.js';

import translations from '@/lang/translations.json';
import { createGettext } from 'vue3-gettext';

export default createGettext({
  availableLanguages: AVAILABLE_LANGS,
  defaultLanguage: DEFAULT_LANG,
  translations,
  silent: true,
});
