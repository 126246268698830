const SUBTYPE_HOMES = 'homes';
const SUBTYPE_PROJECTS = 'projects';
const SUBTYPE_INCOMING = 'incoming';
const SUBTYPE_OUTGOING = 'outgoing';

// Return true if the given file is a system folder.
export const isSystemFolder = (file) => file?.metadata?.is_system;

// Return true if the given file is a 'User Homes' folder.
export const isUserHomesFolder = (file) => file?.metadata?.subtype === SUBTYPE_HOMES;

// Return true if the given file is a 'Projects Shared With Me' folder.
export const isProjectsFolder = (file) => file?.metadata?.subtype === SUBTYPE_PROJECTS;

// Return true if the given file is a 'Incoming Shares' folder.
export const isIncomingFolder = (file) => file?.metadata?.subtype === SUBTYPE_INCOMING;

// Return true if the given file is a 'Outgoing Shares' folder.
export const isOutgoingFolder = (file) => file?.metadata?.subtype === SUBTYPE_OUTGOING;
