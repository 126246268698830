const getInitialState = () => ({
  files: {
    lastDir: 1,
    sortAttr: {
      id: 'name',
      attr: 'name',
    },
  },
  filesListView: {
    lastDir: 1,
    sortAttr: {
      id: 'name',
      attr: 'name',
    },
  },
  addCloudFiles: {
    lastDir: 1,
    sortAttr: {
      id: 'name',
      attr: 'name',
    },
  },
  selectFolder: {
    lastDir: 1,
    sortAttr: {
      id: 'name',
      attr: 'name',
    },
  },
  contacts: {
    lastDir: 1,
    default: 'email',
    sortAttr: {
      id: 'groups',
      attr: 'groups',
      subattr: 'name',
    },
  },
  users: {
    lastDir: 1,
    default: 'name',
    sortAttr: {
      id: 'name',
      attr: 'name',
    },
  },
  userPF: {
    lastDir: 1,
    sortAttr: {
      id: 'pf-name',
      attr: 'name',
    },
  },
  userSSHKey: {
    lastDir: 1,
    sortAttr: {
      id: 'caption-sort',
      attr: 'caption',
    },
  },
  groups: {
    lastDir: 1,
    sortAttr: {
      id: 'name',
      attr: 'name',
    },
  },
  groupMembers: {
    lastDir: 1,
    sortAttr: {
      id: 'group-user-name',
      attr: 'name',
    },
  },
  groupLinks: {
    lastDir: 1,
    sortAttr: {
      id: 'group-name',
      attr: 'name',
    },
  },
  sso: {
    lastDir: 1,
    sortAttr: {
      id: 'name-sort',
      attr: 'name',
    },
  },
  activityExport: {
    lastDir: 1,
    sortAttr: {
      id: 'num-col',
      attr: 'rule_num',
    },
  },
  autoDeleteContacts: {
    lastDir: 1,
    sortAttr: {
      id: 'num-col',
      attr: 'rule_num',
    },
  },
  autoDeleteFiles: {
    lastDir: 1,
    sortAttr: {
      id: 'num',
      attr: 'rule_num',
    },
  },
  autoDeleteUsers: {
    lastDir: 1,
    sortAttr: {
      id: 'num-col',
      attr: 'rule_num',
    },
  },
  externalAutomations: {
    lastDir: 1,
    sortAttr: {
      id: 'num-col',
      attr: 'rule_num',
    },
  },
  fileOperations: {
    lastDir: 1,
    sortAttr: {
      id: 'num-col',
      attr: 'rule_num',
    },
  },
  automationHistory: {
    lastDir: -1,
    sortAttr: {
      id: 'start-time',
      attr: 'start_time',
    },
  },
  schedule: {
    lastDir: 1,
    sortAttr: {
      id: 'name',
      attr: 'name',
    },
  },
  remoteSite: {
    lastDir: 1,
    sortAttr: {
      id: 'name',
      attr: 'name',
    },
  },
  remoteSiteAutomations: {
    lastDir: 1,
    sortAttr: {
      id: 'name',
      attr: 'name',
    },
  },
  remoteAgent: {
    lastDir: 1,
    default: 'name',
    sortAttr: {
      id: 'name',
      attr: 'name',
    },
  },
  cards: {
    lastDir: 1,
    sortAttr: {
      id: 'name-sort',
      attr: 'name',
    },
  },
  invoices: {
    lastDir: -1,
    sortAttr: {
      id: 'generation-date',
      attr: 'generationDate',
    },
  },
  subFolderPermissions: {
    lastDir: 1,
    sortAttr: {
      id: 'pf-user-name',
      attr: 'name',
    },
  },
  projectFolderUsers: {
    lastDir: 1,
    sortAttr: {
      id: 'pf-user-name',
      attr: 'name',
    },
  },
  tracking: {
    lastDir: -1,
    sortAttr: {
      id: 'date-sort',
      attr: 'created',
    },
  },
  classPermissions: {
    lastDir: 1,
    sortAttr: {
      id: 'group-permissions-name',
      attr: 'name',
    },
  },
  infectedFiles: {
    lastDir: 1,
    default: 'file',
    sortAttr: {
      id: 'file',
      attr: 'file_name',
    },
  },
  rejectionList: {
    lastDir: 1,
    default: 'email',
    sortAttr: {
      id: 'email',
      attr: 'email',
    },
  },
  adminContactsList: {
    lastDir: 1,
    default: 'name',
    sortAttr: {
      id: 'name',
      attr: 'name',
    },
  },
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    changeDirection(state, key) {
      if (!key) {
        return;
      }
      state[key].lastDir *= -1;
    },
    changeColumn(state, { key, column }) {
      if (!key) {
        return;
      }
      state[key].sortAttr = {
        id: column.id,
        ...column.sortAttr,
      };
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
};
