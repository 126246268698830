import * as moment from 'moment';
import isString from 'lodash/isString';
import { MOMENT_FORMATS } from '@/utils/cons.js';

import { notify } from '@kyvg/vue3-notification';
import gettext from '@/utils/translationInjector.js';
const { $gettext, $ngettext, interpolate } = gettext;

const units = ['B', 'KB', 'MB', 'GB', 'TB'];

export const addTagKeys = [
  13, // enter
  32, // space
  188, // comma
];

export const getFileSize = (type, bytes) => (type !== 'F' ? foldersize(bytes) : filesize(bytes));

export const foldersize = (items) => {
  const EMPTYITEM = $gettext('empty');
  return items ? items + ' ' + $ngettext('item', 'items', items) : EMPTYITEM;
};

export const filesize = (bytes) => {
  if (isNaN(bytes)) {
    notify({
      text: $gettext('Invalid arguments for filesize'),
      type: 'error',
    });
  }

  let unitIndex = 0,
    result;
  const multiplier = bytes < 0 ? -1 : 1,
    num = Number(bytes) * multiplier;

  const unit = units.find((value, index) => {
    unitIndex = index;
    return Math.pow(1000, index + 1) > num;
  });
  if (bytes) {
    result = bytes / Math.pow(1000, unitIndex);
    if (result % 1 !== 0) {
      result = result.toFixed(2);
    }
  } else {
    result = 0;
  }
  return result + ' ' + (unit || units[unitIndex]);
};

export const convertUnits = (size, from, to) => {
  if (!size || from === to) {
    return size;
  }
  const fromIndex = units.indexOf(from);
  const toIndex = units.indexOf(to);
  if (!~fromIndex || !~toIndex) {
    return size;
  }
  const fromInBytes = size * Math.pow(1000, fromIndex);
  return fromInBytes / Math.pow(1000, toIndex);
};

export const getPercentage = (val, max) => {
  if (!val) {
    return 0;
  } else if (!max) {
    return 100;
  }
  return Math.round((val * 100) / max);
};

export const getDigits = (value, isInteger) => {
  // remove all except numbers and dots
  const string = value.toString().replace(/[^0-9.]/g, '');
  // remove all dot's except first
  const parts = string.split('.');
  return isInteger || parts.length === 1 ? parts[0] : `${parts.shift()}.${parts.join('').slice(0, 3)}`;
};

export const generateCode = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const stripTags = (string) => {
  return string.replace(/(<([^>]+)>)/gi, '');
};

export const isMobile = () => {
  return ((a) => {
    return (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    );
  })(navigator.userAgent || navigator.vendor || window.opera);
};

export const getFormatPrice = (val) => {
  const formatPrice = parseFloat(val).toFixed(2),
    priceParts = formatPrice.split('.'),
    intPart = priceParts[0];

  if (intPart.length > 3) {
    priceParts[0] = intPart.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,');
  }

  return priceParts.join('.');
};

export const getUTCDate = (date) => {
  return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
};

export const btoa = (str) => {
  return window.btoa(unescape(encodeURIComponent(str)));
};

export const atob = (str) => {
  return decodeURIComponent(escape(window.atob(str)));
};

export const getExtension = (fileName) => {
  return fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
};

export const stringEqualsIgnoreCase = (str, compareStr) => {
  if (!isString(str) || !isString(compareStr)) {
    return false;
  }
  return str.toLowerCase() === compareStr.toLowerCase();
};

export const compareModelsByFields = (a, b, fields) => {
  const compare = (a, b, depth = 0) => {
    if (a[fields[depth]] < b[fields[depth]]) {
      return -1;
    }
    if (a[fields[depth]] > b[fields[depth]]) {
      return 1;
    }
    return Boolean(fields[depth + 1]) ? compare(a, b, depth + 1) : 0;
  };
  return compare(a, b);
};

export const sortItems = (array, fields) => array.sort((a, b) => compareModelsByFields(a, b, fields));

export const isProduction = process.env.NODE_ENV === 'production';

// Tiny helper to always be able to see errors in console during dev.
export const consoleLogDebugIfNotProd = (error) => !isProduction && console.debug(error);
export const consoleLogInfoIfNotProd = (error) => !isProduction && console.info(error);
export const consoleLogErrorIfNotProd = (error) => !isProduction && console.error(error);

// Log the error to console as well as to Sentry.
export const logErrorWithSentry = (error) => {
  try {
    consoleLogErrorIfNotProd(error);
    Sentry.captureException(error);
  } catch (error) {
    consoleLogDebugIfNotProd(error);
  }
};

export const currencies = {
  USD: '$',
  GBP: '£',
  EUR: '€',
};

export const renewalPeriods = {
  MONTH_PERIOD: '1 mon',
  YEAR_PERIOD: '1 year',
};

export const getBillingErrorMessage = (error) => {
  if (error?.response) {
    return {
      response: {
        data: {
          msg: error.response.data?.details?.details?.[0] || $gettext('Billing error'),
        },
      },
    };
  }
};

export const formatTimestamp = (timestamp, format = MOMENT_FORMATS.DATE_TIME) => moment.unix(timestamp).format(format);

/*
    Get plain type of any data
    getPlainType(5); //Number
    getPlainType('Some string'); //String
    getPlainType(false); //Boolean
    getPlainType(null); //Null
    getPlainType(undefined); //Undefined
    getPlainType({}); //Object
    getPlainType(Symbol()); //Symbol
    getPlainType([]); //Array
    getPlainType(new Date()); //Date
    getPlainType(function fn() {}); //Function
*/
export function getPlainType(data) {
  return Object.prototype.toString.call(data).match(/\[object ([a-zA-Z]+)]/)[1];
}

export const copyToClipboard = (text, successMessage) => {
  if (navigator.clipboard != undefined) {
    // Chrome, FF
    navigator.clipboard.writeText(text).then(
      () => {
        notify({
          text: successMessage || interpolate($gettext('Copied to clipboard: %{ text }'), { text }),
          type: 'notice',
        });
      },
      (error) => {
        notify({
          text: $gettext('Failed to copy link to clipboard') + '.',
          type: 'error',
        });
        Sentry.captureException(new Error('Failed copy to clipboard'), {
          extra: { error },
        });
      }
    );
  } else if (window.clipboardData) {
    // IE
    window.clipboardData.setData('Text', text);
    notify({
      text: successMessage || interpolate($gettext('Copied to clipboard: %{ text }'), { text }),
      type: 'notice',
    });
  }
};

export const downloadGeneratedFile = (data, fileName) => {
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(data, fileName);
  } else {
    const encodedUri = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.setAttribute('id', 'hiddenDownloader');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName);
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  }
};

export const getFilterItems = (filters) => filters?.map((filter) => filter.items).flat() || [];
export const getFilterItemsIds = (filters) => getFilterItems(filters).map((filter) => filter.id) || [];
export const hasActiveFilter = (filters, filterStates) => getFilterItemsIds(filters).some((key) => filterStates[key]);

export const userPermissionsLabels = () => {
  return {
    manage_files: $gettext('Files'),
    manage_shared_folder: $gettext('Share'),
    manage_automation: $gettext('Automations'),
    manage_user: $gettext('Users'),
    manage_group: $gettext('Groups'),
    manage_site_properties: $gettext('Site Properties'),
    manage_branding: $gettext('Branding'),
    manage_contact: $gettext('Contacts'),
    manage_account: $gettext('Account'),
    manage_billing: $gettext('Billing'),
    manage_site_contact: $gettext('Site contact'),
  };
};

export const getGroupCode = (group) =>
  group.metadata?.translation_id || group.translation_id || group.name.replace(' ', '-');

export const checkSpacesInPath = (path) => {
  if (path && path.match('^ | $')) {
    return $gettext('The path starts or ends with a space');
  }
  return '';
};

export const getNonEmptyObjectProps = (obj) => Object.values(obj).filter((value) => value?.length > 0).length;

export const getAutomationRunStatus = (status) => {
  const labels = {
    running: {
      id: 'new',
      text: $gettext('Running'),
    },
    done: {
      id: 'active',
      text: $gettext('Done'),
    },
    failed: {
      id: 'error',
      text: $gettext('Failed'),
    },
    interrupted: {
      id: 'revoked',
      text: $gettext('Interrupted'),
    },
    crashed: {
      id: 'error',
      text: $gettext('Crashed'),
    },
    pending: {
      id: 'pending',
      text: $gettext('Pending'),
    },
    stopping: {
      id: 'stopping',
      text: $gettext('Stopping'),
    },
  };
  return labels[status] || status;
};

export const normalizeRemoteBucketName = (remote) => remote?.bucket_name || null;
