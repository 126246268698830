import { sortItems } from '@/elements/utils.js';
import { getInvoices, getInvoiceMetadata, payInvoice, payInvoiceByNewCard } from '@/api';
import { compareModelsByFields } from '@/elements/utils.js';

const INVOICE_NUMBER_SORT_REGEX = /\d+/g;

const getInitialState = () => ({
  invoice: {},
  invoiceToPay: null,
  invoiceList: [],
  isErrorOnFetchInvoices: false,
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    setInvoiceList(state, invoiceList) {
      state.invoiceList = invoiceList;
    },
    setInvoice(state, invoice) {
      state.invoice = invoice;
    },
    setInvoiceToPay(state, id) {
      state.invoiceToPay = id;
    },
    setIsErrorOnFetchInvoices(state, status) {
      state.isErrorOnFetchInvoices = status;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getInvoices({ commit }) {
      const { data = [] } = (await getInvoices()) || {};
      const invoices = (data || []).map((invoice) => {
        const [beforeDash, afterDash = null] = invoice.invoice_number.match(INVOICE_NUMBER_SORT_REGEX).map(Number);
        return {
          ...invoice,
          invoiceNumber: invoice.invoice_number,
          generationDate: invoice.generation_date,
          totalPrice: invoice.totalPrice,
          dueDate: invoice.dueDate,
          isPayable: invoice.isPayable,
          beforeDash,
          afterDash,
          isActive: true,
          isDisabledState: false,
          isCheckedState: false,
          isActiveState: false,
        };
      });
      const sortedInvoices = invoices.sort((a, b) =>
        compareModelsByFields(a, b, ['generationDate', 'afterDash', 'beforeDash'])
      );

      commit('setInvoiceList', sortedInvoices.toReversed());
    },
    async getInvoiceMetadata({ commit }, id) {
      const { data } = await getInvoiceMetadata(id);
      if (data) {
        data.vat = parseFloat(data.vat) * 100;
        data.items = sortItems(data.items, ['order_num', 'package_name', 'description']);
        commit('setInvoice', data);
      }
      return data;
    },
    payInvoice({ state }, options = {}) {
      const invoiceId = options.id || state.invoiceToPay;
      if (options.newCard) {
        return payInvoiceByNewCard(invoiceId, options.data);
      } else {
        return payInvoice(invoiceId, options.data || { card_id: null });
      }
    },
  },
};
