import { filesize, convertUnits, getGroupCode } from '@/elements/utils.js';
import {
  getUsers,
  getUserMetadata,
  createUser,
  editUser,
  deleteUser,
  getAllUsersLinks,
  getTrackingUserList,
} from '@/api';
import debounce from 'lodash/debounce';

const getInitialState = () => ({
  lastUpdate: null,
  delayForUpdate: 120000, // 2 mins
  usersCollection: new Map(),
  usersList: [],
  trackingUserCollection: new Map(),
  trackingUserList: [],
  allUsersLinks: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parse: (state, getters, rootState, rootGetters) => (resp) => {
      if (resp.status !== undefined) {
        resp.isActive = resp.status === 'A';
      }

      if (resp.email === null) {
        resp.email = '';
      }

      if (resp.effective_operations === null) {
        resp.effective_operations = resp.user_operations;
      }

      if (resp.user_operations !== undefined) {
        resp.user_operations = rootGetters['operations/parse']('user', resp.user_operations);
      }

      if (resp.effective_operations !== undefined) {
        resp.effective_operations = rootGetters['operations/parse']('user', resp.effective_operations);
        resp.roles = resp.roles || { user: true };
        resp.roles.administrator = rootGetters['operations/check']({
          operations: resp.effective_operations,
          permissions: ['administrator'],
        });
        resp.roles.billing = rootGetters['operations/check']({
          operations: resp.effective_operations,
          permissions: ['billing'],
        });
      }

      if (resp.home_operations !== undefined) {
        resp.home_operations = rootGetters['operations/parse']('file', resp.home_operations);
      }

      if (resp.quota !== undefined) {
        if (resp.quota === -1) {
          resp.quota_unit = 'U';
          resp.quota = '';
        } else {
          const quota = filesize(resp.quota).split(' ');
          resp.quota_unit = ['MB', 'GB'].includes(quota[1]) ? quota[1] : 'MB';
          resp.quota = convertUnits(quota[0], quota[1], resp.quota_unit);
        }
      }
      if (resp.reserved_storage !== undefined) {
        if (resp.reserved_storage <= 0) {
          resp.reserved_storage_unit = 'MB';
          resp.reserved_storage = '0';
        } else {
          const reserved_storage = filesize(resp.reserved_storage).split(' ');
          resp.reserved_storage_unit = ['MB', 'GB'].includes(reserved_storage[1]) ? reserved_storage[1] : 'MB';
          resp.reserved_storage = convertUnits(reserved_storage[0], reserved_storage[1], resp.reserved_storage_unit);
        }
      }
      resp.groups = resp.groups || [];
      if (resp.groups.length > 0) {
        resp.groupsId = resp.groups[0].id;
        resp.groupCode = getGroupCode(resp.groups[0]);
        resp.groups.forEach((group) => {
          group.name = rootGetters['groups/getGroupName'](group.metadata?.translation_id) || group.name;
        });
      }
      if (resp.services && resp.services.length) {
        resp.isSftp = Boolean(resp.services.find((service) => service.service_key === 'SFTP'));
      }

      resp.isSet2fa = resp.auth_methods?.includes('2FA');
      resp.isIpRestriction = resp.auth_methods?.includes('IP');

      // Checked statuses in table
      resp.isDisabledState = false;
      resp.isCheckedState = false;
      resp.isActiveState = false;
      return resp;
    },
    getAttributes: (state, getters, rootState, rootGetters) => (attr) => {
      const user = attr.ids && attr.ids.length ? getters.getUserById(attr.ids[0]) : {}; // check on mass edit action
      const attributes = {};

      if (attr.ids) {
        attributes.ids = attr.ids;
      }
      if (attr.name) {
        attributes.name = attr.name;
      }
      if (attr.hasOwnProperty('notes')) {
        attributes.notes = attr.notes;
      }
      if (attr.isActive !== undefined) {
        attributes.status = attr.isActive ? 'A' : 'D';
      }
      if (attr.email) {
        attributes.email = attr.email.toLowerCase();
      }
      if (attr.roles !== undefined) {
        const roles = Object.keys(attr.roles).filter((el) => attr.roles[el]);
        attributes.user_operations = rootGetters['operations/getAttributes'](
          'user',
          rootGetters['operations/getOperationsByGroups']('user', roles)
        );
      }
      if (attr.home_operations) {
        if (typeof attr.home_operations === 'object') {
          attributes.home_operations = rootGetters['operations/getAttributes']('file', attr.home_operations);
        } else {
          attributes.home_operations = attr.home_operations;
        }
      }
      if (attr.quota || attr.quota === 0 || attr.quota_unit) {
        attributes.quota =
          (attr.quota_unit || user.quota_unit) === 'U'
            ? -1
            : convertUnits(
                attr.quota || attr.quota === 0 ? attr.quota : user.quota,
                attr.quota_unit || user.quota_unit,
                'B'
              );
      }
      if (attr.reserved_storage || attr.reserved_storage === 0 || attr.reserved_storage_unit) {
        attributes.reserved_storage = convertUnits(
          attr.reserved_storage || attr.reserved_storage === 0 ? attr.reserved_storage : user.reserved_storage,
          attr.reserved_storage_unit || user.reserved_storage_unit,
          'B'
        );
      }
      if (attr.language) {
        attributes.language = attr.language;
      }
      if (attr.groupsId) {
        attributes.groups = [attr.groupsId];
      }
      if (attr.isSftp !== undefined) {
        attributes.services = attr.isSftp ? attr.sftpId : [];
      }
      if (typeof attr.sftp_login === 'string') {
        attributes.sftp_login = attr.sftp_login;
      }
      if (attr.sso_login_only !== undefined) {
        attributes.sso_login_only = attr.sso_login_only;
      }
      if (attr.use_expires !== undefined) {
        attributes.use_expires = attr.use_expires;
      }
      if (attr.expires !== undefined) {
        attributes.expires = attr.expires / 1000;
      }
      return attributes;
    },
    getUserById: (state) => (id) => {
      return state.usersCollection.get(id);
    },
    getTrackingUserById: (state) => (id) => {
      return state.trackingUserCollection.get(id);
    },
    getSuperAdmin: (state) => {
      return state.usersList.find((user) => user.super_admin === 'Y');
    },
    getTmpModel: (state, getters, rootState, rootGetters) => {
      return {
        ids: null,
        name: '',
        quota: '',
        quota_unit: 'U',
        reserved_storage: '',
        reserved_storage_unit: 'MB',
        isActive: true,
        email: '',
        user_operations: rootGetters['operations/parse']('user', 1),
        home_operations: rootGetters['operations/parse']('file', 4031),
        effective_operations: rootGetters['operations/parse']('user', 1),
        language: rootState.language,
        has_key: false,
        groups: [],
        groupsId: '',
        groupCode: '',
        services: [],
        roles: {
          administrator: false,
          user: true,
          billing: false,
        },
        isSftp: false,
        sftpId: '',
        pin: '',
        isPin: false,
        sftp_login: '',
        notes: '',
        sso_login_only: false,
        use_expires: false,
        expires: null,
        // Checked statuses in table
        isDisabledState: false,
        isCheckedState: false,
        isActiveState: false,
      };
    },
    shouldUpdateUsers: (state) => () => {
      if (state.lastUpdate) {
        const timeDifference = new Date().getTime() - state.lastUpdate;
        return timeDifference > state.delayForUpdate;
      }
      return true;
    },
  },
  mutations: {
    setUsers(state, users) {
      state.usersCollection = new Map(users.map((user) => [user.id, user]));
      state.usersList = Array.from(state.usersCollection.values());
    },
    setTrackingUsers(state, users) {
      state.trackingUserList = users;
      state.trackingUserCollection = new Map(users.map((user) => [user.id, user]));
    },
    setAllUsersLinks(state, links) {
      state.allUsersLinks = links;
    },
    addUser(state, user) {
      state.usersCollection.set(user.id, user);
      state.usersList = Array.from(state.usersCollection.values());
    },
    updateUsers(state, users) {
      users.forEach((user) => {
        const foundUser = state.usersCollection.get(user.id);
        if (!foundUser) {
          return;
        }
        const { isActiveState, isCheckedState, isDisabledState } = foundUser;
        const updatedUser = { ...foundUser, ...user, isCheckedState, isActiveState, isDisabledState };
        state.usersCollection.set(updatedUser.id, updatedUser);
      });
      state.usersList = Array.from(state.usersCollection.values());
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
    editLastUpdate(state) {
      state.lastUpdate = new Date().getTime();
    },
    deleteUsers(state, userIds) {
      userIds.forEach((id) => {
        state.usersCollection.delete(id);
      });
      state.usersList = Array.from(state.usersCollection.values());
    },
  },
  actions: {
    getUsers: debounce(
      async ({ getters, commit }) => {
        const { data } = await getUsers();
        if (data?.length > 0) {
          const users = data.map((user) => getters.parse(user));
          commit('setUsers', users);
          commit('editLastUpdate');
        }
        return data;
      },
      300,
      {
        leading: true,
        trailing: false,
      }
    ),
    async getUser({ getters, commit }, id) {
      const response = await getUserMetadata(id);
      const user = getters.parse(response.data);
      commit('updateUsers', [user]);
      return user;
    },
    async getUsersList({ getters, commit }) {
      const { data } = await getUsers();
      if (data?.length > 0) {
        const users = data.map((user) => getters['parse'](user));
        commit('setUsers', users);
        commit('editLastUpdate');
      }
      return data;
    },
    async getTrackingUserList({ commit }) {
      const { data } = await getTrackingUserList();
      if (data?.length > 0) {
        commit('setTrackingUsers', data);
      }
      return data;
    },
    async getAllUsersLinks({ commit }) {
      const { data = [] } = await getAllUsersLinks();
      commit('setAllUsersLinks', data);
    },
    async addUser({ getters, commit }, data) {
      const payload = getters.getAttributes(data);
      const response = await createUser(payload);
      const user = getters.parse(response.data);
      commit('addUser', user);
      return user;
    },
    async editUsers({ getters, commit }, data) {
      const payload = getters.getAttributes(data);
      const response = await editUser(payload);
      const users = response.data.map((user) => getters.parse(user));
      commit('updateUsers', users);
      return users;
    },
    async deleteUsers(context, options = {}) {
      const response = await deleteUser({
        ids: options.ids,
        delete_home: options.delete_home || false,
      });
      return response;
    },
  },
};
