import createMutationsSharer from 'vuex-shared-mutations';
import router from '@/router.js';

import { createStore } from 'vuex';

import gettext from '@/utils/translationInjector.js';
const { $gettext } = gettext;

// modules by alphabet
import account from './modules/account.js';
import activity from './modules/activity.js';
import agents from './modules/agents.js';
import auth from './modules/auth.js';
import automations from './modules/automations.js';
import externalAutomationChange from './modules/externalAutomationChange.js';
import bgJobs from './modules/bgJobs.js';
import billing from './modules/billing.js';
import cards from './modules/cards.js';
import contacts from './modules/contacts.js';
import files from './modules/files.js';
import groups from './modules/groups.js';
import inbox from './modules/inbox.js';
import invoice from './modules/invoice.js';
import languages from './modules/languages.js';
import loadDataHandler from './modules/loadDataHandler.js';
import operations from './modules/operations.js';
import pgpKeys from './modules/pgpKeys.js';
import profile from './modules/profile.js';
import projectFolders from './modules/projectFolders.js';
import quarantine from './modules/quarantine.js';
import rejectionList from './modules/rejectionList.js';
import remotes from './modules/remotes.js';
import schedule from './modules/schedule.js';
import services from './modules/services.js';
import share from './modules/share.js';
import sorting from './modules/sorting.js';
import sshKeys from './modules/ssh-keys.js';
import sso from './modules/sso.js';
import tracking from './modules/tracking.js';
import uploadModule from './modules/upload.js';
import users from './modules/users.js';

// additional pages
import downloadPage from './modules/downloadPage.js';
import keyRequestPage from './modules/keyRequestPage.js';
import msft from './modules/msft.js';
import passwordPage from './modules/passwordPage.js';
import returnPage from './modules/returnPage.js';

import '@/api/mqAPI.js';

import { logout, accountMetadata } from '@/api';
import EventBus from '@/elements/eventBus.js';

const MUTATIONS_TO_RESET = [
  'resetState',
  'account/resetState',
  'activity/resetState',
  'agents/resetState',
  'auth/resetState',
  'bgJobs/resetState',
  'billing/resetState',
  'cards/resetState',
  'contacts/resetState',
  'downloadPage/resetState',
  'externalAutomationChange/resetState',
  'files/resetState',
  'groups/resetState',
  'inbox/resetState',
  'invoice/resetState',
  'keyRequestPage/resetState',
  'loadDataHandler/resetState',
  'msft/resetState',
  'passwordPage/resetState',
  'pgpKeys/resetState',
  'profile/resetState',
  'projectFolders/resetState',
  'quarantine/resetState',
  'rejectionList/resetState',
  'remotes/resetState',
  'returnPage/resetState',
  'schedule/resetState',
  'services/resetState',
  'share/resetState',
  'sorting/resetState',
  'upload/resetState',
  'shareUpload/resetState',
  'sshKeys/resetState',
  'users/resetState',
];

const getInitialState = () => ({
  profileName: '',
});

const state = {
  ...getInitialState(),
  language: null,
  accountData: {
    isDefaultLogo: true,
    status: 'A',
    title: '',
    idps: [],
    disclaimers: [],
  },
  errorPageKey: '',
  updateTime: new Date().getTime(),
  delayedCall: null,
  loggedAsContact: false,
  currentRulesKey: 'main',
  isStandAlonePage: null,
  delayedRoute: null,
  isMobile: false,
  loginPageStyle: 'default',
  metadataLoaded: false,
};

const getters = {
  isPageAccessAllowed: (state, getters, rootState) => {
    if (state.currentRulesKey === 'main') {
      return false;
    } else {
      return rootState[state.currentRulesKey].isPageAccessAllowed;
    }
  },
  getShareType: () => (type) => {
    const types = {
      P: $gettext('Public'),
      T: $gettext('Tracked'),
      C: $gettext('Restricted'),
    };
    return types[type];
  },
  isSuspended: (state) => state.accountData.status === 'S',
  getLogo:
    (state) =>
    (forceDefault = false) => {
      return forceDefault || state.accountData.isDefaultLogo
        ? '/img/quatrix_business_logo.svg'
        : '/account/logo?_=' + state.updateTime;
    },
  getLoginLogo: (state) => {
    return state.accountData.isDefaultLogo
      ? '/img/quatrix_business_logo.svg'
      : '/account/logo?_=' + new Date().getTime();
  },
  isSpecialLogin: (state) => state.loginPageStyle === 'globalsectrans',
  specialTextLogo: (state) => (state.loginPageStyle === 'globalsectrans' ? 'Securing success' : ''),
  getLoginSpecialData: (state, getters) => {
    return {
      title: getters.isSpecialLogin ? state.accountData.title || '' : '',
      loginBtn: getters.isSpecialLogin ? $gettext('External Log In') : '',
    };
  },
  getModules: (state, getters, rootState, rootGetters) => {
    const modules = [];
    const checkOperations = rootGetters['profile/checkOperations'];
    if (state.profile.operationsCode) {
      if (checkOperations('manage_files')) {
        modules.push({
          id: 'files',
          title: $gettext('File Explorer'),
          description: $gettext('Store your files in the Quatrix cloud'),
          icon: 'qx-icon icon-files',
          block: 'user',
        });
      }

      if (checkOperations('manage_shared_folder')) {
        const sharedFolderModules = [
          {
            id: 'share',
            title: $gettext('Share Files'),
            description: $gettext('Share files with colleagues or business partners'),
            icon: 'qx-icon icon-share',
            block: 'user',
          },
          {
            id: 'tracking',
            title: $gettext('Tracking'),
            description: $gettext('Track activity history, includes detailed exports'),
            icon: 'qx-icon icon-tracking',
            block: 'user',
          },
          {
            id: 'contacts',
            title: $gettext('Contacts'),
            description: $gettext('Manage your contacts'),
            icon: 'qx-icon icon-contacts',
            block: 'user',
          },
        ];
        sharedFolderModules.forEach((module) => {
          modules.push(module);
        });
      }

      if (checkOperations('manage_automation')) {
        const automationsModules = [
          {
            id: 'internal',
            title: $gettext('Internal Workflows'),
            description: $gettext('Internal Workflows'),
            children: [
              {
                id: 'file-operations',
                title: $gettext('File Operations'),
                visible: true,
              },
              {
                id: 'autodeletes',
                title: $gettext('Delete Files'),
                visible: true,
              },
              {
                id: 'user-autodelete',
                title: $gettext('Delete Users'),
                visible: checkOperations('manage_user'),
              },
              {
                id: 'contact-autodelete',
                title: $gettext('Delete Contacts'),
                visible: checkOperations('manage_user'),
              },
              {
                id: 'activity-export',
                title: $gettext('Activity Reports'),
                visible: true,
              },
            ],
            icon: 'qx-icon icon-internal',
            block: 'automations',
          },
          {
            id: 'external',
            title: $gettext('External Workflows'),
            description: $gettext('External Workflows'),
            children: [
              {
                id: 'external',
                title: $gettext('External Workflows'),
                visible: true,
              },
              {
                id: 'remote-site',
                title: $gettext('Remote Sites'),
                visible: true,
              },
              {
                id: 'remote-agent',
                title: $gettext('Remote Agents'),
                visible: rootState.profile.isAgentTab,
              },
            ],
            icon: 'qx-icon icon-external',
            block: 'automations',
          },
          {
            id: 'schedule',
            title: $gettext('Schedule Overview'),
            description: $gettext('Schedule Overview'),
            icon: 'qx-icon icon-schedule',
            block: 'automations',
          },
        ];

        automationsModules.forEach((module) => {
          if (module.children?.length > 0) {
            module.children = module.children.filter((child) => child.visible);
          }
          modules.push(module);
        });
      }

      if (checkOperations('manage_user') || checkOperations('manage_account')) {
        const adminModules = [
          {
            id: 'account-info',
            title: $gettext('Account Details'),
            description: $gettext('Account Details'),
            icon: 'qx-icon icon-account-details',
            block: 'admin',
            visible: true,
          },
          {
            id: 'users',
            title: $gettext('Users'),
            description: $gettext('Users'),
            icon: 'qx-icon icon-contacts-1',
            block: 'admin',
            visible: checkOperations('manage_user'),
          },
          {
            id: 'links',
            title: $gettext('Links'),
            description: $gettext('Links'),
            icon: 'qx-icon icon-manage-links',
            block: 'admin',
            visible: rootGetters['account/isUserToUserVisibilityEnabled'] && checkOperations('manage_user'),
          },
          {
            id: 'groups',
            title: $gettext('Groups'),
            description: $gettext('Groups'),
            icon: 'qx-icon icon-groups',
            block: 'admin',
            visible: checkOperations('manage_group'),
          },
          {
            id: 'admin-contacts',
            title: $gettext('Contacts'),
            description: $gettext('Contacts'),
            icon: 'qx-icon icon-contacts',
            block: 'admin',
            visible: checkOperations('manage_user'),
          },
          {
            id: 'activity-log',
            title: $gettext('Activity Log'),
            description: $gettext('Track activity history, includes detailed exports'),
            icon: 'qx-icon icon-history',
            block: 'admin',
            visible: true,
          },
          {
            id: 'quarantine',
            title: $gettext('Quarantine'),
            description: $gettext('Quarantine'),
            icon: 'qx-icon icon-quarantine',
            block: 'admin',
            visible: checkOperations('manage_group'),
          },
          {
            id: 'rejection-list',
            title: $gettext('Rejection List'),
            description: $gettext('Rejection List'),
            icon: 'qx-icon icon-rejection-list',
            block: 'admin',
            visible: checkOperations('manage_group'),
          },
          {
            id: 'sso',
            title: $gettext('SSO'),
            description: $gettext('SSO'),
            icon: 'qx-icon icon-sso',
            block: 'admin',
            visible: checkOperations('manage_group'),
          },
          {
            id: 'service-settings',
            title: $gettext('Service Settings'),
            description: $gettext('Service Settings'),
            icon: 'qx-icon icon-service-settings',
            block: 'admin',
            visible: checkOperations('manage_group'),
          },
        ];

        adminModules.forEach((module) => {
          if (module.visible) {
            modules.push(module);
          }
        });
      }

      if (checkOperations('manage_billing')) {
        modules.push({
          id: 'billing',
          title: $gettext('Billing'),
          description: $gettext('Track and pay your invoices directly from your account'),
          children: [
            {
              id: 'invoices',
              title: $gettext('Invoices'),
              visible: true,
            },
            {
              id: 'cards',
              title: $gettext('Cards'),
              visible: true,
            },
          ],
          icon: 'qx-icon icon-billing',
          block: 'admin',
        });
      }
    } else if (state.auth.isLoggedIn) {
      modules.push({
        id: 'inbox',
        title: $gettext('Inbox'),
        description: $gettext('Incomming files and requests tracking'),
        icon: 'qx-icon icon-history',
        block: 'user',
      });
    }
    return modules;
  },
  currentLanguage: (state) => () => state.language,
  getRootFolders:
    (state, getters) =>
    (translate = false) => {
      const currentLanguage = getters['currentLanguage']();

      const translation = (englishName) =>
        currentLanguage === 'en_GB' || !translate ? englishName : $gettext(englishName);

      const folder = (id, englishName) => ({
        translationId: id,
        englishName,
        translation: translation(englishName),
      });
      return [
        folder('Incoming', 'Incoming Shares'),
        folder('Outgoing', 'Outgoing Shares'),
        folder('User homes', 'User Homes'),
        folder('Shared Projects', 'Projects Shared With Me'),
        folder('Trash', 'TRASH'),
      ];
    },
  getFoldersTranslateOptions:
    (state, getters) =>
    (translate = false) => {
      const currentLanguage = getters.currentLanguage();
      const rootFolders = getters.getRootFolders(translate);
      return { currentLanguage, rootFolders, translate };
    },
};

const mutations = {
  setIsMobile(state, value) {
    state.isMobile = value;
  },
  setIsStandAlonePage(state, value) {
    state.isStandAlonePage = value;
  },
  setRules(state, key) {
    state.currentRulesKey = key;
  },
  setAccountData(state, payload) {
    if (typeof payload.default_logo !== 'undefined') {
      state.accountData.isDefaultLogo = payload.default_logo;
    }
    if (payload.status) {
      state.accountData.status = payload.status;
    }
    if (payload.title) {
      state.accountData.title = payload.title;
    }
    if (payload.language) {
      state.language = payload.language;
    }
    if (payload.idps) {
      state.accountData.idps = payload.idps;
    }
    if (payload.disclaimers) {
      state.accountData.disclaimers = payload.disclaimers;
    }
    if (payload.login_page_style) {
      state.loginPageStyle = payload.login_page_style;
    }
    state.metadataLoaded = true;
  },
  setLanguage(state, language) {
    state.language = language;
  },
  setErrorPageKey(state, errorKey) {
    state.errorPageKey = errorKey;
  },
  setUpdateForLogo(state, time) {
    state.updateTime = time;
  },
  setProfileName(state, name) {
    state.profileName = name;
  },
  setDelayedCall(state, args) {
    if (args && args.url && args.url !== '/session/keepalive') {
      state.delayedCall = args;
    }
  },
  clearDelayedCall(state) {
    state.delayedCall = null;
  },
  setLoggedAsContact(state, isContact) {
    state.loggedAsContact = isContact;
  },
  resetDelayedRoute(state) {
    // Save router location before logout, we ll get use back to this location after login
    state.delayedRoute = null;
  },
  pushRouterToMain(state) {
    // If we on main(home) or inbox(contact), we don't need to save that state, cause he will be redirected after login to the same location
    if (!['main'].includes(router.currentRoute.value.name) && !state.isStandAlonePage && !state.errorPageKey) {
      state.delayedRoute = router.currentRoute.value.fullPath;
      router.push({ name: 'main' }).catch(() => {});
    }
  },
  resetState(state) {
    Object.assign(state, getInitialState());
  },
};

const actions = {
  async getAccountMetadata({ commit }) {
    try {
      const { data } = await accountMetadata();
      commit('setAccountData', data);
      return data;
    } catch (error) {
      if (error.response?.status !== undefined) {
        switch (error.response.status) {
          case 404:
            commit('setErrorPageKey', 'errorNotFound');
            break;
          case 502:
            commit('setErrorPageKey', 'errorBadGateway');
            break;
          default:
            commit('setErrorPageKey', 'errorNotFoundDomain');
            break;
        }
      }
    }
  },
  async logout({ commit, dispatch }, source = 'user') {
    try {
      const {
        data: { session_status: sessionStatus },
      } = await logout({ source });
      // If FE keep alive timer run out, but something continued session(other tab send calls, etc)
      if (sessionStatus == 'active' && source === 'timeout') {
        return false;
      } else {
        // We show spinner to avoid blicks of home page
        EventBus.$emit('beforeChangeRoute');
        commit('pushRouterToMain');
        commit('auth/setIsLoggedIn', false);
        // call metadata to get correct sso list
        dispatch('getAccountMetadata');
      }
      dispatch('bgJobs/terminateMqReq');
      return true;
    } catch (error) {
      return false;
    }
  },
  resetStore({ commit }) {
    // Reset state of all described modules
    MUTATIONS_TO_RESET.forEach((mutation) => {
      commit(mutation);
    });
  },
};

export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    account,
    activity,
    agents,
    auth,
    automations,
    bgJobs,
    billing,
    cards,
    contacts,
    externalAutomationChange,
    files,
    groups,
    inbox,
    invoice,
    languages,
    loadDataHandler,
    operations,
    pgpKeys,
    profile,
    projectFolders,
    quarantine,
    rejectionList,
    remotes,
    schedule,
    services,
    share,
    shareUpload: uploadModule(),
    sorting,
    sshKeys,
    sso,
    tracking,
    upload: uploadModule(),
    users,
    downloadPage,
    keyRequestPage,
    msft,
    passwordPage,
    returnPage,
  },
  plugins: [
    createMutationsSharer({
      predicate: [
        'auth/setIsLoggedIn',
        'auth/setMfaCode',
        'auth/setUserEmail',
        'profile/setProfile',
        'pushRouterToMain',
        ...MUTATIONS_TO_RESET,
      ],
    }),
  ],
});
