import { getRejectionList } from '@/api';

const getInitialState = () => ({
  rejectionList: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    setRejectionList(state, rejectionList) {
      state.rejectionList = rejectionList;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getList({ commit }) {
      const { data = [] } = await getRejectionList();
      commit('setRejectionList', data || []);
    },
  },
};
