import { getProjectFolderList, getUsersProjectFolders } from '@/api';
import { notify } from '@kyvg/vue3-notification';
import gettext from '@/utils/translationInjector.js';
const { $gettext } = gettext;

const getInitialState = () => ({
  pfList: [],
  pfUserPermissions: [],
});

const allFields = [
  'list',
  'upload',
  'download',
  'mkdir',
  'delete',
  'rename',
  'share',
  'comments',
  'preview',
  'manage_pf',
];

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parse: () => (data) => {
      return {
        id: data.project_id,
        name: data.name,
        owner: data.owner.email,
        owns: false,
        notify: false,
        operations: null,
        permissions: null,
        isCheckedState: false,
        isDisabledState: false,
        isActive: true,
      };
    },
    userPredefinedPermissions: () => {
      return [
        {
          id: 'list_only',
          text: $gettext('List only'),
          permissions: { list: true },
        },
        {
          id: 'list_and_read',
          text: $gettext('List and read'),
          permissions: {
            list: true,
            download: true,
            comments: true,
            preview: true,
          },
        },
        {
          id: 'read_and_write',
          text: $gettext('Read and write'),
          permissions: {
            list: true,
            upload: true,
            download: true,
            mkdir: true,
            delete: true,
            rename: true,
            comments: true,
            preview: true,
          },
        },
        {
          id: 'full_and_manage',
          text: $gettext('Full and manage'),
          permissions: {
            list: true,
            upload: true,
            download: true,
            mkdir: true,
            delete: true,
            rename: true,
            share: true,
            comments: true,
            preview: true,
            manage_pf: true,
          },
        },
        {
          id: 'none',
          text: $gettext('None'),
          permissions: {
            list: false,
            upload: false,
            download: false,
            mkdir: false,
            delete: false,
            rename: false,
            share: false,
            comments: false,
            preview: false,
            manage_pf: false,
          },
        },
      ];
    },
    userGranularPermissions: () => {
      return [
        {
          id: 'list',
          text: $gettext('List'),
          permissions: {
            list: true,
          },
        },
        {
          id: 'upload',
          text: $gettext('Upload'),
          permissions: { upload: true },
        },
        {
          id: 'download',
          text: $gettext('Download'),
          permissions: { download: true },
        },
        {
          id: 'mkdir',
          text: $gettext('Create folder'),
          permissions: { mkdir: true },
        },
        {
          id: 'delete',
          text: $gettext('Delete'),
          permissions: { delete: true },
        },
        {
          id: 'rename',
          text: $gettext('Rename'),
          permissions: { rename: true },
        },
        {
          id: 'share',
          text: $gettext('Share files'),
          permissions: { share: true },
        },
        {
          id: 'manage_pf',
          text: $gettext('Manage users'),
          permissions: { manage_pf: true },
        },
        {
          id: 'comments',
          text: $gettext('Write comments'),
          permissions: { comments: true },
        },
        {
          id: 'preview',
          text: $gettext('Preview file'),
          permissions: { preview: true },
        },
      ];
    },
    isEqualPermissions: () => (permission1, permission2) => {
      if (!permission1 || !permission2) {
        return false;
      }
      const diff1 = Object.keys(permission1).find((permKey) => permission1[permKey] !== Boolean(permission2[permKey]));
      const diff2 = Object.keys(permission2).find((permKey) => permission2[permKey] !== Boolean(permission1[permKey]));
      return !(diff1 || diff2);
    },
    isEqualPermissionsByAllFields: () => (perm1, perm2) => {
      if (!perm1 || !perm2) {
        return false;
      }
      return allFields.every((field) => perm1[field] === perm2[field]);
    },

    getLabelTextByPermissions: (state, getters) => (permissions) => {
      if (!permissions) {
        return;
      }
      const predefinedPermission = getters.userPredefinedPermissions.find((permission) => {
        return getters.isEqualPermissions(permissions, permission.permissions);
      });
      const noneLabel = $gettext('None');
      if (predefinedPermission) {
        return predefinedPermission.text;
      } else {
        const granularPermissions = getters.userGranularPermissions.filter(
          (permission) => permissions[Object.keys(permission.permissions)[0]]
        );
        return granularPermissions?.map((permission) => permission.text)?.join(', ') || noneLabel;
      }
    },
    getPermissionByOperations: (state, getters, rootState, rootGetters) => (operations) => {
      return rootGetters['operations/parse']('file', operations);
    },
    getOperationsByPermissions: (state, getters, rootState, rootGetters) => (permissions) => {
      return rootGetters['operations/getAttributes']('file', permissions);
    },
  },
  mutations: {
    setList(state, list) {
      state.pfList = list;
    },
    setUsersPermissions(state, list) {
      const folders = new Map();
      list.forEach((item) => {
        const folder = folders.get(item.project_id);
        folders.set(item.project_id, {
          id: item.project_id,
          usersPermissions: folder ? [...folder.usersPermissions, item] : [item],
        });
      });
      state.pfUserPermissions = Array.from(folders.values());
    },
    setPFPermissions(state, permission) {
      const pfIndex = state.pfList.findIndex((projectFolder) => projectFolder.id === permission.project_id);
      state.pfList[pfIndex] = {
        ...state.pfList[pfIndex],
        owns: permission.owner,
        notify: permission.notify,
        operations: permission.operations,
        permissions: permission.permissions,
        isCheckedState: permission.isCheckedState || permission.owner,
        isDisabledState: permission.owner,
        isActive: true,
        wasChanged: false,
        ...(permission.shouldChange && { shouldChange: true }),
        ...(permission.partiallySelected && { partiallySelected: true }),
      };
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getPFList({ commit, getters }) {
      const { data } = await getProjectFolderList();
      const parsedList = data.map((projectFolder) => getters.parse(projectFolder));
      commit('setList', parsedList);
      return data;
    },
    async getUsersPF({ commit, getters }, options = {}) {
      const { data } = await getUsersProjectFolders(options.ids);
      if (data.length === 0 && options.isCopyPermissions) {
        notify({
          type: 'warning',
          text: $gettext('This user is not subscribed to any Project Folders.'),
        });
        return data;
      }
      const projectFolderOperations = new Map();
      if (options.ids.length > 1) {
        data.forEach((item) => {
          if (projectFolderOperations.has(item.project_id)) {
            const projectFolder = projectFolderOperations.get(item.project_id);
            const includesOperations = projectFolder.operations.includes(item.operations);
            projectFolderOperations.set(item.project_id, {
              operations: includesOperations ? [...projectFolder.operations, item.operations] : [],
              users: [...new Set([...projectFolder.users, item.user_id])],
              notify: [projectFolder.notify, item.notify].every((notify) => notify),
            });
          } else {
            projectFolderOperations.set(item.project_id, {
              operations: [item.operations],
              users: [item.user_id],
              notify: item.notify,
            });
          }
        });
      }

      data.forEach((perm) => {
        //If called from "Copy permissions from another user" we need skip owner property
        if (options.isCopyPermissions || options.ids.length > 1) {
          perm.owner = false;
        }
        if (!perm.owner) {
          if (options.ids.length > 1) {
            const projectFolder = projectFolderOperations.get(perm.project_id);
            const noOperations = projectFolder?.operations?.length === 0;
            const notAllUsersSubscribed = projectFolder?.users?.length !== options.ids.length;
            if (noOperations || notAllUsersSubscribed) {
              perm.shouldChange = true;
              perm.operations = 0;
              perm.notify = false;
            } else {
              perm.notify = projectFolder.notify;
            }
            if (notAllUsersSubscribed) {
              perm.isCheckedState = false;
              perm.partiallySelected = true;
            } else {
              perm.isCheckedState = true;
            }
          }
          if (options.ids.length === 1) {
            perm.isCheckedState = true;
          }
          if (perm.shouldChange) {
            perm.permissions = null;
          } else {
            perm.permissions = getters.getPermissionByOperations(perm.operations);
          }
        }
        commit('setPFPermissions', perm);
      });
      commit('setUsersPermissions', data);

      return data;
    },
  },
};
