import { logErrorWithSentry } from '@/elements/utils.js';
import EventBus from '@/elements/eventBus';

const DELAY_FOR_UPDATE = 120000; // 2 mins

function getInitialState() {
  return {
    lastUpdatedTimestamps: {},
    dataLoadingStatus: {},
    reactivityTrigger: true,
  };
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    getLastUpdatedTimestamp: (state) => (componentName) => {
      return state.lastUpdatedTimestamps[componentName];
    },
    getDataLoadingStatus: (state) => (componentName) => {
      return state.dataLoadingStatus[componentName];
    },
    reactivityTrigger(state) {
      return state.reactivityTrigger;
    },
    getIsUpdateDataNeeded: (state, getters) => (componentName) => {
      const lastTimestamp = getters.getLastUpdatedTimestamp(componentName);
      if (getters.getDataLoadingStatus(componentName)) {
        return false;
      }
      if (lastTimestamp) {
        const timeDifference = new Date().getTime() - lastTimestamp;
        return timeDifference > DELAY_FOR_UPDATE;
      }
      return true;
    },
  },
  mutations: {
    setLastUpdatedTimestamp(state, componentName) {
      state.lastUpdatedTimestamps[componentName] = new Date().getTime();
    },
    setDataLoadingStatus(state, options) {
      state.dataLoadingStatus[options.componentName] = options.value;
      state.reactivityTrigger = !state.reactivityTrigger;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async handleDataFetching(
      { commit, getters },
      { componentName, fetchingFunction, onResolve, onReject, onFinally, forceUpdate, defaultReject = true }
    ) {
      try {
        if (forceUpdate || getters.getIsUpdateDataNeeded(componentName)) {
          commit('setDataLoadingStatus', { componentName, value: true });
          await fetchingFunction?.();
          commit('setDataLoadingStatus', { componentName, value: false });
          commit('setLastUpdatedTimestamp', componentName);
        }
        await onResolve?.();
      } catch (error) {
        commit('setDataLoadingStatus', { componentName, value: false });
        onReject?.(error);
        if (defaultReject && error?.response) {
          EventBus.$emit('handle-error', { response: error.response });
        }
        logErrorWithSentry(error);
        return error;
      } finally {
        onFinally?.();
      }
    },
  },
};
