import { getSSOList } from '@/api';

const getInitialState = () => ({
  ssoList: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parse: () => (resp) => {
      return {
        ...resp,
        isActive: resp.status === 'A',
        isDisabledState: false,
        isCheckedState: false,
        isActiveState: false,
      };
    },
  },
  mutations: {
    setSSO(state, sso) {
      state.ssoList = sso;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getList({ commit, getters }) {
      try {
        const { data } = await getSSOList();
        const ssoList = (data || []).map((sso) => getters.parse(sso));
        commit('setSSO', ssoList);
      } catch (error) {
        if (error?.response?.status === 403 && error?.response?.data?.code === 50) {
          // clear sso list on Permission denied error (403/50)
          commit('setSSO', []);
        }
        return Promise.reject(error);
      }
    },
  },
};
