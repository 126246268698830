import { sortItems } from '@/elements/utils.js';
import { createSsh, editSsh, deleteSsh, getSshMetadata, getUsersSsh } from '@/api';

const getInitialState = () => ({
  userKeys: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parseKey: (state) => (key) => {
      const expires = key.expires * 1000;
      return {
        id: key.id,
        caption: key.caption,
        fingerprint: key.fingerprint,
        expires,
        isActive: key.status === 'A' && (expires === 0 || (expires !== 0 && expires > new Date().getTime())),
        // Checked statuses in table
        isDisabledState: false,
        isCheckedState: false,
        isActiveState: false,
      };
    },
    getAttributes: (state) => (key) => {
      return {
        ...(key.caption && { caption: key.caption }),
        ...(key.key && { key: key.key }),
        ...((key.expires || key.expires === 0) && { expires: key.expires }),
        ...(key.user_id && { user_id: key.user_id }),
      };
    },
    getKeyById: (state) => (id) => {
      return state.userKeys.find((key) => key.id === id);
    },
  },
  mutations: {
    setUserKeys(state, keys) {
      state.userKeys = keys;
    },
    updateKeys(state, key) {
      let keyIndex = state.userKeys.findIndex((item) => item.id === key.id);
      const { isActiveState, isCheckedState, isDisabledState } = state.userKeys[keyIndex];
      const updatedKey = { ...key, isCheckedState, isActiveState, isDisabledState };
      state.userKeys[keyIndex] = updatedKey;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getKeysByUser({ getters, commit }, id) {
      const { data } = await getUsersSsh(id);
      let keys = (data || []).map((key) => getters.parseKey(key));
      keys = sortItems(keys, ['caption']);
      commit('setUserKeys', keys);
      return data;
    },
    async getKey({ getters, commit }, id) {
      const { data } = await getSshMetadata(id);
      const newKey = getters.parseKey(data);
      commit('updateKeys', newKey);
      return data;
    },
    async addKey({ getters }, payload) {
      return createSsh(getters.getAttributes(payload));
    },
    async editKey({ getters }, payload = {}) {
      return editSsh(payload.id, getters.getAttributes(payload));
    },
    async deleteKey(context, id) {
      return deleteSsh(id);
    },
  },
};
