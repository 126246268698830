import { getLanguageList } from '@/api';

const getInitialState = () => ({
  languages: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    selectOptions: (state) => () => {
      return state.languages.map((lang) => ({ value: lang.id, text: lang.name }));
    },
  },
  mutations: {
    setLanguages(state, languages) {
      state.languages = languages;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getList({ commit }) {
      try {
        const { data } = await getLanguageList();
        commit('setLanguages', data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
