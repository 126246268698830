import cloneDeep from 'lodash/cloneDeep';
import isString from 'lodash/isString';
import { normalizeRemoteBucketName, logErrorWithSentry } from '@/elements/utils.js';
import { REMOTE_TYPES } from '@/utils/cons.js';

import { addAutomationSchedule, updateSchedule, deleteSchedule } from '@/api';

import gettext from '@/utils/translationInjector.js';
const { $gettext, interpolate } = gettext;

const AGE_SIZE_KEYS = [
  ['min_age', 'minAge', 'isFilterByAge'],
  ['max_age', 'maxAge', 'isFilterByAge'],
  ['min_size', 'minSize', 'isFilterBySize'],
  ['max_size', 'maxSize', 'isFilterBySize'],
];
const DEFAULT_INPLACE_SUFFIX = {
  inplace: true,
  partial_suffix: '',
};
const RESTRICTED_REMOTES = ['sftp', 'dropbox', 'box', 'onedrive'];
const RESTRICTED_BACKUP_REMOTES = ['sftp', 'box'];
const INPLACE_PARTIAL_SUFFIX_OPTIONS = ['quatrix', 'sftp', 'agent', 'agent_pool'];

const getInitialState = () => ({
  name: '',
  notes: '',
  isActive: true,
  operationType: 'move',
  isUsePatterns: false,
  patterns: null,
  isFilterBySize: false,
  isFilterByAge: false,
  isReserveCopy: false,
  isBackup: false,
  isHardDelete: false,
  isRemoveEmptyDirsAfterDelete: false,
  isDeleteEmptyDirsAfterMove: false,
  isCreateEmptyDirs: true,
  isScheduleActive: false,
  initCron: { schedule: '0 0 */1 * *' },
  startDate: null,
  isNotifyOnFailureOnly: true,
  notifyEmails: [],
  minAge: {
    value: null,
    units: 'd',
  },
  maxAge: {
    value: null,
    units: 'd',
  },
  minSize: {
    value: null,
    units: 'M',
  },
  maxSize: {
    value: null,
    units: 'M',
  },
  inplaceSuffix: cloneDeep(DEFAULT_INPLACE_SUFFIX),
  source: {
    type: 'quatrix',
    key: 'quatrix',
    id: null, // quatrix folder id
    path: null, // quatrix folder path
    remote_path: null, // path for remote site
    bucket_name: null,
    options: {},
  },
  destination: {
    type: null,
    key: null,
    id: null,
    path: null,
    reserve_copy: {
      path: '',
      id: null,
    },
    backup: {
      id: null,
      path: '',
      add_time_suffix: false,
      use_backup: false,
    },
    remote_path: null,
    bucket_name: null,
  },
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    sourceType: (state) => state.source.type,
    destinationType: (state) => state.destination.type,
    isSourceQuatrix: (state) => state.source.type === 'quatrix',
    isDestinationQuatrix: (state) => state.destination.type === 'quatrix',
    isDeleteOperationType: (state) => state.operationType === 'delete',
    destinationRemoteType: (state, getters, rootState, rootGetters) =>
      rootGetters['remotes/getRemoteById'](state.destination.id)?.site_type,
    sourceRemoteType: (state, getters, rootState, rootGetters) =>
      rootGetters['remotes/getRemoteById'](state.source.id)?.site_type,
    allowReserveCopy: (state, getters) =>
      !RESTRICTED_REMOTES.includes(getters.destinationRemoteType) && state.operationType !== 'sync',
    allowBackup: (state, getters) => !RESTRICTED_BACKUP_REMOTES.includes(getters.destinationRemoteType),
    allowSameRemotes: (state, getters) =>
      getters.destinationRemoteType === getters.sourceRemoteType &&
      !RESTRICTED_REMOTES.includes(getters.destinationRemoteType),
    isSameRemote: (state, getters) => {
      if (getters.isSourceQuatrix && getters.isDestinationQuatrix) {
        return getters.allowSameRemotes;
      }
      return state.destination.key === state.source.key && getters.allowSameRemotes;
    },
    allowInplacePartialSuffix: (state, getters) => {
      const isAllowedType = (type) => INPLACE_PARTIAL_SUFFIX_OPTIONS.includes(type);
      const allowedDestinationType = getters.destinationRemoteType
        ? isAllowedType(getters.destinationRemoteType)
        : isAllowedType(state.destination.type);
      return !getters.isSameRemote && allowedDestinationType;
    },
    getDefaultName: (state) => () => {
      switch (state.operationType) {
        case 'copy':
          return $gettext('Copying files');
        case 'sync':
          return $gettext('Syncing files');
        case 'move':
          return $gettext('Moving files');
        case 'delete':
          return $gettext('Deleting files');
        default:
          // don't show error to user to not confuse him, just capture error in sentry
          if (state.operationType) {
            logErrorWithSentry(new Error(`Unknown operation type: ${state.operationType}`));
          }
          return '';
      }
    },
    getRemotePathLabel: (state, getters, rootState, rootGetters) => (typeId) => {
      const type = rootGetters['remotes/getRemoteById'](typeId)?.site_type;
      if (type === 's3') {
        return $gettext('Type remote bucket path');
      }
      if (type === 'azureblob') {
        return $gettext('Type remote container path');
      }
      if (typeId === 'reserve_copy') {
        return $gettext('Path');
      }
      if (typeId === 'backup') {
        return $gettext('Backup path');
      }
      return $gettext('Type remote folder path');
    },
    getRemotePlaceholder: (state, getters, rootState, rootGetters) => (typeId) => {
      const type = rootGetters['remotes/getRemoteById'](typeId)?.site_type;
      let example = '"home/folder_path"';

      if (type === 's3') {
        example = '"bucket_name/folder_path"';
      }
      if (type === 'azureblob') {
        example = '"container_name/folder_path"';
      }

      return interpolate($gettext('e.g. %{ example }'), { example }, true);
    },
    getFilledSourceDestination:
      (state, getters, rootState, rootGetters) =>
      ({ type, id, path }) => {
        const values = {
          id,
          key: type === 'quatrix' ? 'quatrix' : id,
          type,
          bucket_name: type === 'quatrix' ? null : normalizeRemoteBucketName(rootGetters['remotes/getRemoteById'](id)),
        };

        if (type === 'quatrix') {
          values.path = path;
        } else {
          values.remote_path = path;
        }

        return values;
      },
    getPreparedItem: (state, getters) => () => {
      return {
        name: state.name || getters.getDefaultName(),
        email: state.notifyEmails,
        notes: state.notes,
        isActive: state.isActive,
        options: getters.getOptions(),
      };
    },
    getOptions: (state, getters) => () => {
      const options = {
        source: getters.sourceType,
        ...(state.startDate && { start_date: state.startDate.getTime() / 1000 }),
        notify_options: { on_failure_only: state.isNotifyOnFailureOnly },
        filter: getters.getFilter(),
        operation: getters.getOperation(),
      };

      if (getters.isDeleteOperationType) {
        options[getters.sourceType] = getters.getDeleteTarget();
        return options;
      }

      const isReserveCopy = getters.allowReserveCopy && state.isReserveCopy;
      const isSameRemote = getters.allowSameRemotes && getters.isSameRemote;

      if (isSameRemote) {
        options.same_remote = getters.getSameRemote(isReserveCopy);
      } else {
        options[getters.sourceType] = getters.getSource();
        options[getters.destinationType] = getters.getDestination(isReserveCopy);
      }

      options.config = getters.getConfig();

      return options;
    },
    getFilter: (state) => () => {
      const filter = {};

      AGE_SIZE_KEYS.forEach(([key, internalKey, isEnabledKey]) => {
        if (state[internalKey].value && state[isEnabledKey]) {
          filter[`use_${key}`] = true;
          filter[key] = state[internalKey].value + state[internalKey].units;
        } else {
          filter[`use_${key}`] = false;
        }
      });

      filter.use_patterns = state.isUsePatterns;
      if (state.patterns) {
        filter.patterns = ['+ ' + state.patterns];
      }

      return filter;
    },
    getOperation: (state) => () => {
      return {
        type: state.operationType,
        ...(state.operationType === 'delete' && { delete_empty_dst_dirs: state.isRemoveEmptyDirsAfterDelete }),
        delete_empty_src_dirs: state.operationType === 'move' && state.isDeleteEmptyDirsAfterMove,
        create_empty_src_dirs: state.isCreateEmptyDirs,
      };
    },
    getDeleteTarget: (state, getters) => () => {
      return {
        ...getters.getSource(),
        options: { hard_delete: state.isHardDelete },
      };
    },
    getSameRemote: (state, getters) => (isReserveCopy) => {
      const { id: destId, remote_path: destPath, reserve_copy } = state.destination;
      const { id: sourceId, remote_path: sourcePath } = state.source;

      return {
        ...(sourceId && { id: sourceId }),
        ...(getters.isSourceQuatrix && destId && { destination_id: destId }),
        ...(!getters.isSourceQuatrix && { destination_path: destPath, path: sourcePath }),
        ...(isReserveCopy && !reserve_copy.id && { reserve_copy_path: reserve_copy.path }),
        ...(isReserveCopy && reserve_copy.id && { reserve_copy_id: reserve_copy.id }),
      };
    },
    getSource: (state, getters) => () => {
      return {
        id: state.source.id,
        path: getters.isSourceQuatrix ? state.source.path : state.source.remote_path,
      };
    },
    getDestination: (state, getters) => (isReserveCopy) => {
      return {
        id: state.destination.id,
        path: getters.isDestinationQuatrix ? state.destination.path : state.destination.remote_path,
        ...(isReserveCopy && { reserve_copy_path: state.destination.reserve_copy.path }),
        ...(getters.isDestinationQuatrix && isReserveCopy && { reserve_copy_id: state.destination.reserve_copy.id }),
      };
    },
    getConfig: (state, getters) => () => {
      const { path, id, add_time_suffix } = state.destination.backup;
      const { inplace, partial_suffix } = state.inplaceSuffix;

      return {
        use_backup: state.isBackup,
        suffix: '',
        ...(!getters.isDestinationQuatrix && {
          backup_dir: state.isBackup ? path : '',
        }),
        ...(getters.isDestinationQuatrix && state.isBackup && { backup_dir_id: id || '' }),
        add_time_suffix: state.isBackup && add_time_suffix,
        ...(getters.allowInplacePartialSuffix && { inplace: !inplace, partial_suffix }),
      };
    },
    getScheduleChangeAction: (state) => (itemId) => {
      const changeTypes = {
        delete: !state.isScheduleActive && state.initCron.id,
        add: state.isScheduleActive && !state.initCron.id,
        edit: state.isScheduleActive && state.initCron.id && state.initCron.schedule !== state.cronExpression,
      };

      const changeType = Object.keys(changeTypes).find((type) => changeTypes[type]);

      if (!changeType) {
        return null;
      }

      const changeActions = {
        delete: () => deleteSchedule(state.initCron.id),
        add: () =>
          addAutomationSchedule(itemId, {
            schedule: state.cronExpression,
          }),
        edit: () =>
          updateSchedule(state.initCron.id, {
            schedule: state.cronExpression,
          }),
      };

      return changeActions[changeType];
    },
  },
  mutations: {
    setInitItemSettings(state, item) {
      state.name = item.name;
      state.isActive = item.isActive;
      state.notes = item.notes;
      state.notifyEmails = item.email;
      state.startDate = item.options.start_date ? new Date(item.options.start_date * 1000) : null;
    },
    setInitNotifyOptions(state, notifyOptions) {
      if (!notifyOptions) {
        return;
      }
      state.isNotifyOnFailureOnly = notifyOptions.on_failure_only;
    },
    setInitOperation(state, operation) {
      if (!operation) {
        return;
      }
      state.operationType = operation.type;
      state.isRemoveEmptyDirsAfterDelete = operation.delete_empty_dst_dirs;
      state.isDeleteEmptyDirsAfterMove = operation.delete_empty_src_dirs;
      state.isCreateEmptyDirs = operation.create_empty_src_dirs;
    },
    setInitFilters(state, filter) {
      if (!filter) {
        return;
      }
      state.isFilterByAge = filter.use_min_age || filter.use_max_age;
      state.isFilterBySize = filter.use_min_size || filter.use_max_size;
      AGE_SIZE_KEYS.forEach(([key, internalKey]) => {
        if (filter[`use_${key}`] && filter[key]) {
          const [, value, units] = filter[key].split(/([0-9]+)/);
          state[internalKey] = {
            value,
            units,
          };
        }
      });
      state.isUsePatterns = filter.use_patterns;
      state.patterns = filter.use_patterns ? filter.patterns[0]?.replace(/(^\+ )/, '') : null;
    },
    setInitInplaceSuffix(state, config) {
      if (!config) {
        return;
      }
      const { inplace, partial_suffix } = config;
      const isDefined = (val) => typeof val !== 'undefined';

      const setField = (value, name) => {
        if (isDefined(value) && name !== 'inplace') {
          state.inplaceSuffix[name] = value;
        } else if (isDefined(value)) {
          state.inplaceSuffix[name] = !value;
        }
      };

      setField(inplace, 'inplace');
      setField(partial_suffix, 'partial_suffix');
    },
    setNewItemSettings(state, userEmail) {
      state.notifyEmails = [userEmail];
    },
    toggleInplaceCheckbox(state) {
      state.inplaceSuffix.inplace = !state.inplaceSuffix.inplace;
    },
    setPartialSuffix(state, value) {
      state.inplaceSuffix.partial_suffix = value;
    },
    setSource(state, payload) {
      Object.keys(payload).forEach((key) => {
        state.source[key] = payload[key];
      });
    },
    setDestination(state, payload) {
      Object.keys(payload).forEach((key) => {
        state.destination[key] = payload[key];
      });
    },
    setReserveCopyDestination(state, value) {
      state.destination[value.key] = value;
    },
    toggleFieldState(state, field) {
      state[field] = !state[field];
    },
    setFieldValue(state, { field, value }) {
      state[field] = value;
    },
    clearReserveBackup(state, { key, type }) {
      if (isString(state[key][type]?.path)) {
        state[key][type].path = '';
      }
      if (type === 'reserve_copy') {
        if (isString(state[key][type]?.id)) {
          state[key][type].id = null;
        }
      }
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    setInitItem({ commit, dispatch, rootState }, { item, isNew }) {
      if (isNew) {
        commit('setNewItemSettings', rootState.auth.userEmail);
        dispatch('setNewItemDestination');
        return;
      }

      const { options } = item;

      if (options.operation.type === 'delete') {
        dispatch('setDeleteTypeTarget', options);
      } else if (options.same_remote) {
        dispatch('setSameRemoteSourceDestination', options);
      } else if (options.source === 'quatrix') {
        dispatch('setQuatrix', { options, mutation: 'setSource' });
        dispatch('setRemoteTarget', { options, mutation: 'setDestination' });
      } else {
        dispatch('setQuatrix', { options, mutation: 'setDestination' });
        dispatch('setRemoteTarget', { options, mutation: 'setSource' });
      }

      commit('setInitItemSettings', item);
      commit('setInitNotifyOptions', options.notify_options);
      commit('setInitOperation', options.operation);
      commit('setInitInplaceSuffix', options.config);
      commit('setInitFilters', options.filter);
    },
    setQuatrix({ getters, commit }, { options, mutation }) {
      const { id, path } = options.quatrix;
      const filledTarget = getters.getFilledSourceDestination({ type: 'quatrix', id, path });

      commit(mutation, filledTarget);
    },
    setRemoteTarget({ getters, commit }, { options, mutation }) {
      const type = REMOTE_TYPES.find((remote) => options[remote]);
      const { id, path } = options[type];
      const filledTarget = getters.getFilledSourceDestination({ type, id, path });

      commit(mutation, filledTarget);
    },
    setNewItemDestination({ state, getters, commit, rootState }) {
      const remote = rootState.remotes.remotes[0];
      const filledDestination = getters.getFilledSourceDestination({
        id: remote ? remote.id : state.source.id,
        type: remote ? remote.type : state.source.type,
        path: remote ? '' : state.source.path,
      });

      commit('setDestination', filledDestination);
    },
    setSameRemoteSourceDestination({ getters, commit }, { source, same_remote }) {
      const { id, path, destination_path } = same_remote;
      const filledSource = getters.getFilledSourceDestination({ id, path, type: source });
      commit('setSource', filledSource);

      const filledDestination = getters.getFilledSourceDestination({
        type: source,
        id: same_remote.destination_id || id,
        path: destination_path,
      });
      commit('setDestination', filledDestination);
    },
    setDeleteTypeTarget({ getters, commit }, options) {
      const { source } = options;
      const { id, path } = options[source];
      const filledTarget = getters.getFilledSourceDestination({ type: source, id, path });

      commit('setSource', filledTarget);
      commit('setFieldValue', { field: 'isHardDelete', value: options[source].options?.hard_delete });
    },
    onChangeKey({ commit, getters, rootGetters }, { value, key }) {
      const remoteId = value[0].value;
      const isSource = key === 'source';
      const quatrix = remoteId === 'quatrix' && 'quatrix';
      const remote = quatrix || rootGetters['remotes/getRemoteById'](remoteId);
      const payload = {
        key: remoteId,
        path: '',
        remote_path: '',
        type: quatrix || remote.type,
        id: remote.id || null,
        bucket_name: quatrix ? null : normalizeRemoteBucketName(remote),
      };

      if (!isSource && !getters.allowReserveCopy) {
        commit('setFieldValue', { field: 'isReserveCopy', value: false });
      }

      commit(isSource ? 'setSource' : 'setDestination', payload);
      commit('clearReserveBackup', { key, type: 'reserve_copy' });
      commit('clearReserveBackup', { key, type: 'backup' });
    },
    saveCron({ state }, automationId) {
      return state.isScheduleActive && addAutomationSchedule(automationId, { schedule: state.cronExpression });
    },
  },
};
