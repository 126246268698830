import { getAgentsList, getAgentsPools } from '@/api';

const getInitialState = () => ({
  agentsList: [],
  poolsList: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    fullCollection: (state, getters) => getters.parse([...state.poolsList, ...state.agentsList]),
    parse: () => (items) => {
      return items.map((item) => ({
        ...item,
        isActive: item.status === 'A',
        isPool: Boolean(item.agent_ids),
      }));
    },
    getListItem: (state, getters) => (id) => {
      return getters.fullCollection.find((item) => item.id === id);
    },
  },
  mutations: {
    setAgentsList(state, agentsList) {
      state.agentsList = agentsList;
    },
    setPoolsList(state, poolsList) {
      state.poolsList = poolsList;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getAgentsList({ commit }) {
      const { data = [] } = await getAgentsList();
      commit('setAgentsList', data || []);
    },
    async getAgentsPools({ commit }) {
      const { data = [] } = await getAgentsPools();
      commit('setPoolsList', data || []);
    },
  },
};
