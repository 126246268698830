import { getPercentage, filesize } from '@/elements/utils';
import { QUATRIX_PLANS } from '@/utils/cons.js';
import { getAccountInfo } from '@/api';
import gettext from '@/utils/translationInjector.js';
const { $gettext } = gettext;

const getInitialState = () => ({
  packagesList: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parse: () => (resp) => {
      const property = resp.properties;
      return {
        ...resp,
        name: QUATRIX_PLANS[resp.key],
        properties: {
          ...resp.properties,
          ...(property.user_used &&
            property.max_users && { users_percentage: getPercentage(property.user_used, property.max_users) }),
          ...(property.storage_used && { storage_used_text: filesize(property.storage_used) }),
          ...(property.disc_quota && { disc_quota_text: filesize(property.disc_quota) }),
          ...(property.storage_used &&
            property.disc_quota && {
              storage_percentage: getPercentage(property.storage_used, property.disc_quota),
            }),
          ...(property.max_file_size && {
            max_file_size: property.max_file_size < 0 ? $gettext('Unlimited') : filesize(property.max_file_size),
          }),
          ...(property.files_per_share && {
            files_per_share:
              parseInt(property.files_per_share, 10) === -1 ? $gettext('Unlimited') : property.files_per_share,
          }),
          ...(property.max_external_file_automations && {
            max_external_file_automations: parseInt(property.max_external_file_automations, 10) || 0,
          }),
        },
      };
    },
    basePackage: (state) => () => {
      return state.packagesList.find((pack) => pack.base === true);
    },
    automationPackage: (state) => {
      return state.packagesList.find(({ key }) => ['QEA', 'QEAC', 'QEAT'].includes(key));
    },
    totalExternalAutomations: (state) => {
      const basePackage = state.packagesList.find((pack) => pack.base);
      return basePackage?.properties?.max_external_file_automations;
    },
    usedExternalAutomations: (state) => {
      const base = state.packagesList.find((pack) => pack.base === true);
      return base?.properties?.file_automation_used;
    },
    isUserToUserVisibilityEnabled: (state) => {
      return state.packagesList.find((pack) => pack.properties.show_user_to_user_visibility_tab === 'Y') || false;
    },
  },
  mutations: {
    setPackageList(state, packagesList) {
      state.packagesList = packagesList;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getAccountInfo({ state, getters, commit }) {
      const { data } = await getAccountInfo();
      const packagesList = data.map((pack) => getters.parse(pack));
      commit('setPackageList', packagesList);

      const base = state.packagesList.find(({ base }) => base);
      if (base?.id) {
        const { max_users, disc_quota, total_reservations } = base.properties;
        commit(
          'profile/setUserLimits',
          {
            user_limit: max_users,
            storage_limit: disc_quota,
            total_reservations: total_reservations,
          },
          { root: true }
        );
      }
      return data;
    },
  },
};
