<template>
  <v-btn
    v-if="!invisible"
    :id="`${id}-button`"
    :ripple="false"
    :class="`btn-${buttonStyle[state]}`"
    :data-id="id"
    :variant="buttonVariant[variant || state]"
    :type="type"
    :disabled="inProgress || disabled"
    :block="isBlock"
    :tag="tag"
    :tabindex="tabindex"
    :href="href"
    :size="size"
    :target="href && target"
    @click="onClick">
    <div
      v-if="inProgress"
      class="spinner"
      :title="title">
      <qx-spinner-inline />
    </div>
    <div :class="{ hidden: inProgress }">
      <span>{{ title }}</span>
      <slot name="button-content" />
    </div>
    <qx-tooltip
      v-if="tooltipTitle"
      :hover-opens="true"
      :target="`#${id}-button`"
      :location="tooltipPosition">
      <div v-html="$sanitize(tooltipTitle)" />
    </qx-tooltip>
  </v-btn>
</template>

<script>
import QxSpinnerInline from '@/elements/QxSpinnerInline.vue';
import QxTooltip from '@/elements/QxTooltip.vue';

export default {
  name: 'QxButton',
  components: {
    QxSpinnerInline,
    QxTooltip,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    action: {
      type: Function,
      default: null,
    },
    state: {
      type: String,
      required: true,
      validator: (value) => {
        return [
          'active',
          'active-inverse',
          'passive',
          'active-red',
          'active-blue',
          'blue-text',
          'red-text',
          'blue-text-hover-fill',
          'helper',
          'additional',
          'border-blue',
          'border-blue-outline',
          'danger',
          'link',
          'passive-link',
          'hover-underline',
        ].includes(value);
      },
    },
    variant: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'button',
    },
    preventDefault: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invisible: {
      type: Boolean,
      default: false,
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'button',
      validator: (value) => {
        return ['button', 'span'].includes(value);
      },
    },
    tabindex: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: 'blank',
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
      validator: (value) => {
        return ['x-small', 'small', 'large', 'x-large', ''].includes(value);
      },
    },
    classes: {
      type: String,
      default: '',
    },
    tooltipTitle: {
      type: String,
      default: null,
    },
    tooltipPosition: {
      type: String,
      default: 'top',
      validator: (value) => ['start', 'end', 'top', 'bottom'].includes(value),
    },
  },
  data() {
    return {
      showTooltip: false,
      buttonStyle: {
        active: 'custom-orange',
        'active-inverse': 'custom-orange-inverse',
        passive: 'default',
        'active-red': 'custom-red',
        'active-blue': 'custom-blue',
        'blue-text': 'blue-text',
        'red-text': 'red-text',
        'blue-text-hover-fill': 'blue-text-hover-fill',
        helper: 'helper',
        additional: 'custom-light-blue',
        'border-blue': 'border-info-blue',
        'border-blue-outline': 'border-info-blue-outline',
        danger: 'danger',
        link: 'link',
        'passive-link': 'passive-link',
        'hover-underline': 'hover-underline',
      },
      buttonVariant: {
        active: 'flat',
        'active-inverse': 'flat',
        'active-red': 'flat',
        passive: 'flat',
        'active-blue': 'flat',
        'blue-text': 'plain',
        'red-text': 'plain',
        'blue-text-hover-fill': 'text',
        helper: 'text',
        additional: 'text',
        'border-blue': 'outlined',
        danger: 'flat',
        link: 'text',
        'passive-link': 'text',
      },
    };
  },
  methods: {
    onClick(event) {
      if (this.preventDefault) {
        event.preventDefault();
      }
      this.action?.();
    },
  },
};
</script>

<style lang="scss">
.v-btn--block {
  width: 100%;
}
.v-btn {
  min-width: auto;
  letter-spacing: normal;
  text-transform: none;
  font-size: 15px;
  padding: 0.375rem 0.75rem;
  position: relative;
  border: 1px solid;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .hidden {
    visibility: hidden;
    opacity: 0;
  }
  &.btn-lg {
    padding: 13px 36px;
  }
  &.btn-link,
  &.btn-passive-link {
    outline: none;
    border: none;
    box-shadow: none !important;
  }
  &.btn-link {
    color: $base-blue;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &.btn-passive-link {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &.btn-hover-underline {
    padding-left: 0;
    color: $gray-800;
    border: none;
    box-shadow: none;
    transition: color 0.2s;
    .button-content span {
      text-decoration: underline;
      text-decoration-color: transparent;
      transition: text-decoration-color 0.2s;
    }
    &:hover,
    &:focus {
      color: $base-grey;
      box-shadow: none;
      border-color: transparent;
      .button-content span {
        text-decoration-color: $base-grey;
      }
    }
  }
  &.btn-default,
  &.btn-custom-red,
  &.btn-custom-blue,
  &.btn-custom-orange,
  &.btn-hover-underline {
    &.v-btn--disabled {
      opacity: 0.65;
      border-color: transparent;
      .v-btn__overlay {
        opacity: 0;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/scss/_buttons.scss';
</style>
