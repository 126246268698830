import { mapState } from 'vuex';
import * as moment from 'moment';

import { DEFAULT_LANG } from '@/utils/cons.js';

export default {
  computed: {
    ...mapState({
      language: (state) => state.language,
    }),
  },
  watch: {
    language() {
      this.setLang();
    },
  },
  methods: {
    setLang(newLanguage = this.language) {
      const language = newLanguage || DEFAULT_LANG;
      if (language !== this.$language.current && Object.keys(this.$language.available).includes(language)) {
        this.$language.current = language;
        moment.locale(language);
      }
    },
  },
};
