import { passwordResetMetadata } from '@/api';

const getInitialState = () => ({
  isMultipleAccounts: false,
  isPageAccessAllowed: null,
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    setIsPageAcessAllowed(state, value) {
      state.isPageAccessAllowed = value;
    },
    setIsMultipleAccounts(state, value) {
      state.isMultipleAccounts = value;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getMetadata({ commit }, id) {
      try {
        const {
          data: { default_logo: defaultLogo, multiple_accounts: isMultipleAccounts },
        } = await passwordResetMetadata(id);
        commit('setIsPageAcessAllowed', true);
        commit('setAccountData', { default_logo: defaultLogo }, { root: true });
        commit('setIsMultipleAccounts', isMultipleAccounts);
      } catch (error) {
        commit('setIsPageAcessAllowed', false);
        if (error.response?.status === 401) {
          commit('setErrorPageKey', 'errorIPRestriction');
        }
        return Promise.reject(error);
      }
    },
  },
};
