import { getUserPgpKeys, updatePgpKey, createPgpKey } from '@/api';
import { atob, btoa } from '@/elements/utils.js';

const getInitialState = () => ({
  id: null,
  name: '',
  public: null,
  private: null,
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parse: (state) => (resp) => {
      return {
        ...resp,
        ...(resp.public && { public: atob(resp.public) }),
        ...(resp.private && { private: atob(resp.private) }),
      };
    },
    getAttributes: (state) => (attr) => {
      return {
        ...{ name: attr.name },
        ...(attr.public && { public: btoa(attr.public) }),
        ...(attr.private && { private: btoa(attr.private) }),
      };
    },
    parseKey: () => (key) => atob(key),
  },
  mutations: {
    setPGPKeys(state, keys) {
      state.id = keys.id;
      state.name = keys.name;
      state.public = keys.public;
      state.private = keys.private;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getUserKeys({ getters, commit }, userId) {
      const { data } = await getUserPgpKeys(userId);
      const key = getters.parse(data);
      commit('setPGPKeys', key);
      return key;
    },
    async addKeys({ getters, commit }, payload) {
      const attributes = getters.getAttributes(payload);
      const { data } = await createPgpKey(attributes);
      const key = getters.parse(data);
      commit('setPGPKeys', key);
      commit('profile/setPGPKeys', true);
      return key;
    },
    async editKeys({ getters, commit }, payload) {
      const attributes = getters.getAttributes(payload);
      const { data } = await updatePgpKey(payload.id, attributes);
      const key = getters.parse(data);
      commit('setPGPKeys', key);
      return key;
    },
  },
};
