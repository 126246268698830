import { getVirusNotifyEmails, editVirusNotifyEmails } from '@/api';

const getInitialState = () => ({
  virusNotifyEmails: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    setVirusNotifyEmails(state, emails) {
      state.virusNotifyEmails = emails;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getVirusNotifyEmails({ commit }) {
      const { data = [] } = await getVirusNotifyEmails();
      commit('setVirusNotifyEmails', data?.emails || []);
    },
    async editVirusNotifyEmails({ commit }, options) {
      const { data = [] } = await editVirusNotifyEmails(options);
      commit('setVirusNotifyEmails', data?.emails || []);
    },
  },
};
