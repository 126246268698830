const getInitialState = () => ({
  officeFiles: [
    {
      type: 'Excel',
      formats: ['ods', 'xlsb', 'xlsm', 'xlsx', 'csv'],
    },
    {
      type: 'OneNote',
      formats: ['one', 'onetoc2'],
    },
    {
      type: 'Visio',
      formats: ['vsdx'],
    },
    {
      type: 'Word',
      formats: ['docm', 'docx', 'odt'],
    },
    {
      type: 'PowerPoint',
      formats: ['odp', 'ppsx', 'pptx'],
    },
    {
      type: 'WopiTest',
      formats: ['wopitest'],
    },
  ],
  isPageAccessAllowed: null,
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    getOfficeFormat: (state) => (format) => {
      return state.officeFiles.find((icon) => icon.formats.includes(format));
    },
  },
  mutations: {
    setIsMsftAllowed(state, value) {
      state.isPageAccessAllowed = value;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
};
