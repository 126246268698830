export const EMAIL_REG_EXP = new RegExp(
  /^(([^<>()[\]\\.,;:\s@\"\u0250-\ue007]+(\.[^<>()[\]\\.,;:\s@\"\u0250-\ue007]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([^?!\-\_\@]([a-zA-Z\-0-9]*)+\.)+[a-zA-Z]{2,22}))$/i
);

export const FILE_NAME_HAS_DOTS_REG_EXP = new RegExp(/^\.{1,2}$/);

export const FILE_NAME_HAS_SLASHES_REG_EXP = new RegExp(/\\|\//);

export const EMAIL_FOOTER_REGEX = new RegExp(/\\/);

export const IP_REGEX = new RegExp(
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(-([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$|\/([1-9]|[12]\d|3[0-2])$)?$/i
);

export const URL_REGEX = new RegExp(
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i
);

export const CARD_NUMBER_REGEX = new RegExp(/^[0-9]{12,19}$/);

export const CARD_HOLDER_REGEX = new RegExp(/^[A-z /\&-_‘]{3,50}$/);

export const ZIP_REGEX = new RegExp(/^[a-zA-Z0-9-\s]{0,10}$/);

export const SFTP_REGEX = new RegExp(/^[0-9A-Za-z\._-]*$/);

export const HOST_REGEX = new RegExp(
  /^((([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6})|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))$/
);

export const UUID_REGEX = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);

export const AZURE_BUCKET_NAME_REGEX = new RegExp(/^([a-z0-9]-?)*[a-z0-9]$/);

export const B2_BUCKET_NAME_REGEX = new RegExp(/^(?!b2-)[a-z0-9-]+$/i);

export const S3_BUCKET_NAME_REGEX = new RegExp(/^(?!xn--)[a-z0-9]([a-z0-9-]*(\.(?!\.))?)+[a-z0-9]$/);

export const GCS_NO_DOTS_BUCKET_NAME_REGEX = new RegExp(/^(?!goog)[a-z0-9][a-z0-9_-]+[a-z0-9]$/);

export const GCS_WITH_DOTS_BUCKET_NAME_REGEX = new RegExp(
  /^(?!goog)[a-z0-9][a-z0-9_-]{0,62}\.([a-z0-9_-]{1,63}\.)*[a-z0-9_-]{0,62}[a-z0-9]$/
);

export const FILE_TRASH_PATH = new RegExp(/^\/trash\//i);
