import { formatTimestamp } from '@/elements/utils';
import { getRemoteSiteList, getRemoteSite, getRemotePermissions, getAutomationRemotes } from '@/api';

import gettext from '@/utils/translationInjector.js';
const { $gettext } = gettext;

const defaultSitePremissions = {
  account_wide: {
    allow: false,
    manage: false,
  },
  group_wide: [],
  per_user: [],
};

const getInitialState = () => ({
  remoteSitesList: [],
  sitePermissions: defaultSitePremissions,
  siteModifiedPermissions: null,
  remotes: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    getRemoteSiteById: (state) => (id) => state.remoteSitesList.find((site) => site.id === id),
    getRemoteById: (state) => (id) => state.remotes.find((site) => site.id === id),
    parseRemoteSite: () => (site) => {
      site.isActive = site.status === 'A';
      site.isDisabledState = false;
      site.isCheckedState = false;
      site.isActiveState = false;
      site.createdFormatted = formatTimestamp(site.created);
      site.modifiedFormatted = formatTimestamp(site.modified);
      return site;
    },
    getPermissionTypeName: () => (permissions) => {
      const { account_wide, group_wide, per_user } = permissions;
      const publicType = account_wide?.allow && account_wide?.manage;
      const custom = !publicType && (group_wide?.length > 0 || per_user?.length > 0);

      const defaultType = $gettext('Private');
      const customText = $gettext('Custom rules');

      const types = [
        {
          value: publicType,
          text: $gettext('Public full'),
        },
        {
          value: !account_wide?.allow,
          text: defaultType,
        },
        {
          value: account_wide?.allow && !account_wide?.manage,
          text: $gettext('Public use only'),
        },
      ];

      const selectedType = types.find(({ value }) => value)?.text || defaultType;
      if (custom) {
        return selectedType + ' + ' + customText;
      }
      return selectedType;
    },
  },
  mutations: {
    setRemoteSites(state, sites) {
      state.remoteSitesList = sites;
    },
    setRemoteSiteStateChecked(state, id) {
      const siteIndex = state.remoteSitesList.findIndex((site) => site.id === id);
      if (siteIndex !== -1) {
        state.remoteSitesList[siteIndex] = { ...state.remoteSitesList[siteIndex], isCheckedState: true };
      }
    },
    setRemoteSitePermissions(state, sitePermissions) {
      state.sitePermissions = sitePermissions;
    },
    updateRemoteSite(state, remotes) {
      remotes.forEach((remote) => {
        const remoteIndex = state.remoteSitesList.findIndex((item) => item.id === remote.id);
        if (remoteIndex !== -1) {
          const { isActiveState, isCheckedState, isDisabledState } = state.remoteSitesList[remoteIndex];
          const updatedRemote = {
            ...state.remoteSitesList[remoteIndex],
            ...remote,
            isCheckedState,
            isActiveState,
            isDisabledState,
          };
          state.remoteSitesList[remoteIndex] = updatedRemote;
        } else {
          state.remoteSitesList.push(remote);
        }
      });
    },
    setRemotes(state, remotes) {
      state.remotes = remotes;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
    setModifiedPermissions(state, siteModifiedPermissions) {
      state.siteModifiedPermissions = siteModifiedPermissions;
    },
    resetAllPermissions(state) {
      state.siteModifiedPermissions = null;
      state.sitePermissions = defaultSitePremissions;
    },
  },
  actions: {
    async getRemoteSites({ getters, commit }) {
      const { data } = await getRemoteSiteList();
      commit('setRemoteSites', data.map(getters.parseRemoteSite));
    },
    async getRemoteSite({ getters, commit }, { id: remoteId, options }) {
      const { data } = await getRemoteSite(remoteId, options);
      const remote = getters.parseRemoteSite(data);
      commit('updateRemoteSite', [remote]);
      return remote;
    },
    async getRemoteSitePermissions({ commit }, { id }) {
      if (id) {
        const { data } = await getRemotePermissions(id);
        commit('setRemoteSitePermissions', data);
      }
    },
    async getAutomationRemotes({ commit }) {
      const { data = [] } = await getAutomationRemotes();
      commit('setRemotes', data || []);
    },
  },
};
