<template>
  <div id="app">
    <!-- Shows message about IE will not be supported soon, shown only in IE -->
    <browser-no-support-message />
    <!-- Shows errors, 404, not-released share/return, etc -->
    <error-page v-if="isErrorPage" />

    <template v-else>
      <!-- Spinner between router navigation -->
      <qx-spinner
        :id="spinnerId"
        :global="true" />

      <!-- We use v-show because handle errors logins is there -->
      <authorization
        v-show="!isContentAllowed"
        @successLogin="showSpinner"
        @pageReady="hideSpinner" />

      <!-- Content -->
      <pages v-if="isContentAllowed" />
    </template>

    <!-- Notification Container -->
    <notify />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { defineAsyncComponent } from 'vue';

import notify from '@/elements/notifications.vue';
import qxSpinner from '@/elements/spinner.vue';
import EventBus from '@/elements/eventBus.js';

import translationsMixin from '@/mixins/translations.js';
import BrowserNoSupportMessage from '@/elements/BrowserNoSupportMessage.vue';

const IS_USER_VOICE = (config.VUE_APP_IS_USER_VOICE || process.env.VUE_APP_IS_USER_VOICE || 'Y') === 'Y';
const IS_CAPTCHA = (config.VUE_APP_IS_CAPTCHA || process.env.VUE_APP_IS_CAPTCHA || 'Y') === 'Y';
const IS_DASHBOARD = (config.VUE_APP_IS_DASHBOARD || process.env.VUE_APP_IS_DASHBOARD || 'Y') === 'Y';

export default {
  name: 'App',
  components: {
    Authorization: defineAsyncComponent(() => import('@/components/Authorization.vue')),
    Pages: defineAsyncComponent(() => import('@/components/Pages.vue')),
    errorPage: defineAsyncComponent(() => import('@/components/errorPages/errorPage.vue')),
    notify,
    qxSpinner,
    BrowserNoSupportMessage,
  },
  mixins: [translationsMixin],
  provide: {
    isDashboard: IS_DASHBOARD,
  },
  metaInfo: {
    // Change dynamicly title attribute in <head>
    titleTemplate: (title) => (title ? `${title} | Quatrix` : 'Quatrix'),
  },
  data() {
    return {
      spinnerId: 'loading',
    };
  },
  computed: {
    ...mapState({
      errorPageKey: (state) => state.errorPageKey,
      isLoggedIn: (state) => state.auth.isLoggedIn,
      isStandAlonePage: (state) => state.isStandAlonePage,
      isForcedLogin: (state) => state.auth.isForcedLogin,
    }),
    ...mapGetters({
      isPageAccessAllowed: 'isPageAccessAllowed',
    }),
    isContentAllowed() {
      if (this.isForcedLogin) {
        return false;
      }

      if (this.isStandAlonePage) {
        return this.isPageAccessAllowed;
      }

      return this.isLoggedIn;
    },
    isErrorPage() {
      return Boolean(this.errorPageKey);
    },
  },
  watch: {
    isForcedLogin(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.hideSpinner();
      }
    },
    isLoggedIn(newValue) {
      if (newValue) {
        this.hideSpinner();
      }
    },
    isPageAccessAllowed(newValue) {
      if (newValue) {
        this.hideSpinner();
      }
    },
    errorPageKey() {
      this.hideSpinner();
    },
  },
  created() {
    if (IS_USER_VOICE) {
      this.setupUserVoice();
    }
    EventBus.$on('changePage', this.showSpinner);
  },
  mounted() {
    if (IS_CAPTCHA) {
      this.setupCaptcha();
    }
  },
  methods: {
    showSpinner() {
      this.spinnerId = 'loading';
    },
    hideSpinner() {
      this.spinnerId = '';
    },
    setupUserVoice() {
      // Include the UserVoice JavaScript SDK (only needed once on a page)
      let UserVoice = window.UserVoice || [];
      (function () {
        var uv = document.createElement('script');
        uv.async = true;
        uv.src = 'https://maytech.uservoice.com/widget_environment/g70HVXnoiJDurq59OVegQ.js';
        uv.type = 'text/javascript';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(uv, s);
      })();

      //
      // UserVoice Javascript SDK developer documentation:
      // https://help.uservoice.com/hc/en-us/articles/360035418493
      //

      // Set colors
      UserVoice.push([
        'set',
        {
          accent_color: '#448dd6',
          trigger_color: 'white',
          trigger_background_color: '#ff7700',
          strings: {
            contact_title: 'Contact us',
            contact_message_placeholder: 'Report an issue, suggest a new feature or leave us your feedback.',
          },
        },
      ]);

      // Add default trigger to the bottom-right corner of the window:
      UserVoice.push(['addTrigger', { mode: 'contact', trigger_position: 'bottom-right' }]);

      // Autoprompt for NPS® Rating (only displayed under certain conditions)
      UserVoice.push(['autoprompt', {}]);

      window.UserVoice = UserVoice;
    },
    setupCaptcha() {
      let recaptchaScript = document.createElement('script');
      recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=explicit');
      document.body.appendChild(recaptchaScript);
    },
  },
};
</script>

<style lang="scss" src="./assets/scss/style.scss"></style>
