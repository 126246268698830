import EventBus from '@/elements/eventBus.js';
import { getJobStatuses, mqReq } from '@/api';
import { logErrorWithSentry, consoleLogErrorIfNotProd } from '@/elements/utils.js';
import gettext from '@/utils/translationInjector.js';
const { $gettext, interpolate } = gettext;
import { notify } from '@kyvg/vue3-notification';

const MQ_TIMEOUT = parseInt(process.env.VUE_APP_MQ_TIMEOUT, 10) || 900000; // 15 min
const JOB_POLLING_INTERVAL = parseInt(process.env.VUE_APP_JOB_POLLING_INTERVAL, 10) || 3000; // 3 sec

// Map job actions to user friendly translated names.
const getOperationName = (action) => {
  const JOB_ACTION_NAMES = {
    'users:delete': $gettext('Delete users'),
    'remote:test': $gettext('Test connection'),
    'files:delete': $gettext('Delete files'),
    'files:create': $gettext('Copy files'),
    'files:update': $gettext('Move files'),
    'automation:run': $gettext('Run automation'),
  };
  return JOB_ACTION_NAMES[action] || '';
};

const formatJobActionError = (message, action) =>
  interpolate(
    message,
    {
      operationName: `<strong>${getOperationName(action)}</strong>`,
    },
    true
  );

const getInitialState = () => ({
  clientBgJobs: [], // { id, action, success, error, finally }
  pollingTimer: null, // a timer for polling status of the registered jobs
  timeoutTimers: new Map(), // timers for job timeout: jobId -> timerId
  pollingInterval: JOB_POLLING_INTERVAL, // Interval between job status polls.
  jobTimeout: MQ_TIMEOUT, // Timeout after which we give up waiting/polling for a job.
  mqAbortController: null,
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    allJobIds: (state) => state.clientBgJobs.map((job) => job.id),
    hasNoJobs: (state, getters) => getters.allJobIds.length === 0,
    getJobById: (state) => (id) => {
      return state.clientBgJobs.find((job) => job.id === id);
    },
  },
  mutations: {
    addClientJob(state, job) {
      state.clientBgJobs.push(job);
    },
    removeClientJob(state, id) {
      const index = state.clientBgJobs.findIndex((item) => item.id === id);
      if (index !== -1) {
        state.clientBgJobs.splice(index, 1);
      }
    },

    setPollingTimer(state, timerId) {
      state.pollingTimer = timerId;
    },
    clearPollingTimer(state, timerId) {
      clearTimeout(state.pollingTimer);
      state.pollingTimer = null;
    },

    setTimeoutTimer(state, { jobId, timerId }) {
      state.timeoutTimers.set(jobId, timerId);
    },
    deleteTimeoutTimer(state, jobId) {
      clearTimeout(state.timeoutTimers.get(jobId));
      state.timeoutTimers.delete(jobId);
    },
    clearTimeoutTimers(state) {
      state.timeoutTimers.forEach(clearTimeout);
      state.timeoutTimers.clear();
    },

    resetState(state) {
      clearTimeout(state.pollingTimer);
      state.timeoutTimers.forEach(clearTimeout);
      Object.assign(state, getInitialState());
    },

    setMqController(state, controller) {
      state.mqAbortController = controller;
    },
  },
  actions: {
    addJob({ commit, dispatch, getters }, job) {
      commit('addClientJob', job);
      dispatch('startJobTimeoutTimer', job.id);
      dispatch('startPollingTimer');
    },
    removeJob({ commit, getters }, id) {
      commit('deleteTimeoutTimer', id);
      commit('removeClientJob', id);
      getters.hasNoJobs && commit('clearPollingTimer'); // Stop polling if there are no more jobs.
    },

    startPollingTimer({ state, commit, dispatch }) {
      commit('clearPollingTimer');
      commit(
        'setPollingTimer',
        setTimeout(() => dispatch('pollJobStatuses'), state.pollingInterval)
      );
    },
    restartPollingTimerIfActive({ state, dispatch }) {
      if (state.pollingTimer !== null) {
        dispatch('startPollingTimer');
      }
    },

    startJobTimeoutTimer({ state, commit, dispatch }, jobId) {
      commit('setTimeoutTimer', {
        jobId,
        timerId: setTimeout(() => dispatch('processJobTimeout', jobId), state.jobTimeout),
      });
    },

    processJobTimeout({ getters, dispatch }, jobId) {
      dispatch('processJobStatus', { id: jobId, status: 'MQ_TIMEOUT' });
    },

    async runMqReq({ commit, dispatch }, { lastModified, etag } = {}) {
      const abortController = new AbortController();
      commit('setMqController', abortController);
      try {
        const response = await mqReq(abortController, lastModified, etag);
        const status = response.status;
        dispatch('mqReq', response);
        if (response.status >= 200 && response.status <= 400) {
          const timeout = setTimeout(
            () =>
              dispatch('runMqReq', {
                lastModified: response.headers['last-modified'],
                etag: response.headers['etag'],
              }),
            status === 200 ? 0 : 1000
          );

          commit('setTimeoutTimer', {
            jobId: 'mqReq',
            timerId: timeout,
          });
        }
      } catch (error) {
        logErrorWithSentry(error);
      }
    },

    terminateMqReq({ state, commit, dispatch }) {
      state.mqAbortController?.abort();
      commit('setMqController', null);
      dispatch('removeJob', 'mqReq');
    },

    // Handle MQ long poll response.
    mqReq({ dispatch, getters }, response) {
      try {
        if (response.status !== 200) {
          //  TODO: This seems to always fire immediately after the page
          //  load. Maybe investigate?
          consoleLogErrorIfNotProd(response);
          return;
        }

        const { data: body } = response;

        const result = body.result;

        if (body.status.errno === 0 && result.action) {
          EventBus.$emit(result.action, result.data);
        }

        if (body.job_id) {
          // Try to find a job handler. It may not be found in case the polling
          // already processed it.
          const job = getters.getJobById(body.job_id);
          if (job !== undefined) {
            dispatch('processJob', { job, response: body });
          }
        }
      } catch (error) {
        logErrorWithSentry(error);
      }
    },

    processJob({ dispatch }, { job, response }) {
      dispatch('removeJob', job.id);
      try {
        if (response.status.errno === 0) {
          typeof job.success === 'function' && job.success(response);

          EventBus.$emit(job.action, response.result.data);
        } else {
          typeof job.error === 'function' && job.error(response);
        }
      } catch (error) {
        logErrorWithSentry(error);
        notify({
          text: formatJobActionError($gettext('Failed to process the %{ operationName } operation status'), job.action),
          type: 'error',
        });
      } finally {
        typeof job.finally === 'function' && job.finally(response);
      }
    },

    processJobStatus({ dispatch, getters }, { id, status, result }) {
      const job = getters.getJobById(id);
      if (job === undefined) {
        return; // The job has already been handled.
      }

      switch (status) {
        case 'PENDING':
        case 'STARTED':
          // TODO: figure out a way to unobtrusively notify the user about
          // in-progress jobs.
          break;
        case 'SUCCESS':
          dispatch('processJob', { job, response: result });
          break;
        case 'FAILURE':
        case 'MQ_TIMEOUT':
          // Payload may contain an error message deep inside, prefer it if found.
          const errmsg =
            result?.result?.error?.msg ||
            formatJobActionError(
              status === 'FAILURE'
                ? $gettext('The operation %{ operationName } has failed')
                : $gettext('The operation %{ operationName } is still in progress'),
              job.action
            );
          dispatch('processJob', {
            job,
            response: {
              status: {
                errno: 1,
                errmsg,
              },
            },
          });
          break;
        default:
          logErrorWithSentry(new Error(`Unknown job status: ${status}`));
      }
    },

    async pollJobStatuses({ dispatch, getters }) {
      try {
        // Skip making HTTP request if there are no more jobs.
        if (getters.hasNoJobs) {
          return; // NOTE: the finally block will restart the timer.
        }

        const response = await getJobStatuses(getters.allJobIds);
        response.data.forEach((status) => dispatch('processJobStatus', status));
      } catch (error) {
        // No user visible notifications, because user should know nothing about
        // the job polling stuff going on here.
        logErrorWithSentry(error);
      } finally {
        dispatch('restartPollingTimerIfActive');
      }
    },
  },
};
