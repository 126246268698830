import { getScheduleOverview } from '@/api';

const getInitialState = () => ({
  scheduleList: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    getScheduleAutomationById: (state) => (id) => {
      return state.scheduleList.find((automation) => automation.automation_id === id);
    },
  },
  mutations: {
    setScheduleList(state, scheduleList) {
      state.scheduleList = scheduleList;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getScheduleOverview({ commit }) {
      const { data = [] } = await getScheduleOverview();
      commit('setScheduleList', data || []);
    },
  },
};
