const VALID_KEY_TYPES = ['ssh-rsa', 'ssh-dss', 'ssh-ed25519'];
const ED25519_METADATA_BYTES_PART = [0, 0, 0, 11, 115, 115, 104, 45, 101, 100, 50, 53, 53, 49, 57, 0, 0, 0, 32]; // 4-byte length, a 11-byte string containing (again) the algorithm name, another 4-byte length
const ED25519_METADATA_LENGTH = ED25519_METADATA_BYTES_PART.length;

const getBytesAndSplit = (bytes) => {
  const sizeLen = 4;
  if (bytes.length < sizeLen + 1) {
    return false;
  }
  const sizeBytes = bytes.slice(0, sizeLen);
  const bytesAndTail = bytes.slice(sizeLen);
  const size =
    (sizeBytes.charCodeAt(0) << (8 * 3)) +
    (sizeBytes.charCodeAt(1) << (8 * 2)) +
    (sizeBytes.charCodeAt(2) << 8) +
    (sizeBytes.charCodeAt(3) << 0);
  if (bytesAndTail.length < size) {
    return false;
  }
  const integerBytes = bytesAndTail.slice(0, size);
  const tail = bytesAndTail.slice(size);
  return [integerBytes, tail];
};

const checkIntegers = (num, bytes) => {
  let resBytes = bytes;
  let i;
  let ref;
  let result;
  for (i = 0, ref = num; 0 <= ref ? i < ref : i > ref; 0 <= ref ? ++i : --i) {
    result = getBytesAndSplit(resBytes);
    if (result === false) {
      return false;
    }
    resBytes = result[1];
  }
  return resBytes.length === 0;
};

export default (rawKey) => {
  const keyTokens = rawKey.trim().split(' ');
  if (keyTokens.length < 2) {
    return false;
  }
  const humanType = keyTokens[0];
  const keyBase64 = keyTokens[1];
  if (!VALID_KEY_TYPES.includes(humanType)) {
    return false;
  }
  let keyBytes;
  try {
    keyBytes = window.atob(keyBase64);
  } catch {
    return false;
  }
  switch (humanType) {
    case 'ssh-rsa':
      return isRsaDssValid(keyBytes, 2);
    case 'ssh-dss':
      return isRsaDssValid(keyBytes, 4);
    case 'ssh-ed25519':
      return isEd25519Valid(keyBytes);
    default:
      return false;
  }
};

const isRsaDssValid = (keyBytes, num) => {
  const typeSizeParse = getBytesAndSplit(keyBytes);
  if (!typeSizeParse) {
    return false;
  }
  const keyBody = typeSizeParse[1];
  return checkIntegers(num, keyBody);
};

const isEd25519Valid = (keyBytes) => {
  try {
    const decodedBytes = new Uint8Array([...keyBytes].map((char) => char.charCodeAt(0)));

    const ed25519KeyLength = ED25519_METADATA_LENGTH + 32; // 32-byte - actual public key value

    const isKeyLengthValid = decodedBytes.length === ed25519KeyLength;
    const isMetadataValid =
      decodedBytes.slice(0, ED25519_METADATA_LENGTH).toString() === ED25519_METADATA_BYTES_PART.toString();

    if (isKeyLengthValid && isMetadataValid) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
