import store from '@/store/index.js';

import { EMAIL_CODE } from '@/utils/cons.js';

export const mfaLoginInterceptor = (response) => {
  if (response.status === 207) {
    store.commit('auth/setMfaCode', response.headers['mfa-code']);
  }
  return response;
};

export const mfaLoginErrorInterceptor = (error) => {
  if (error.response.status === 429) {
    store.commit('auth/setLoginError', 'rateLimit');
    return false;
  }
  return Promise.reject(error);
};

export const lookupInterceptor = (response) => {
  const code = response.headers['mfa-code'];
  store.commit('auth/setMfaCode', code);
  if (+code === EMAIL_CODE) {
    store.commit('auth/setLoginError', 'wrongCredentials');
  }
  return response;
};
