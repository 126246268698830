<template>
  <notifications
    :duration="5000"
    :reverse="true"
    :close-on-click="false"
    width="510px"
    position="top right">
    <template #body="props">
      <div
        ref="body"
        class="notify"
        @mouseenter="onMouseEnter(props)"
        @mouseleave="onMouseLeave(props)">
        <div
          :id="`${props.item.type}-${props.item.id}`"
          :ref="(props.item.data && props.item.data.id) || props.item.id"
          :class="props.item.type">
          <div
            :class="getIcon(props.item.type)"
            class="notify-icon-box">
            <span class="icon-notify" />
          </div>
          <div class="msg-box">
            <a
              :ref="`closeBtn-${props.item.id}`"
              class="close"
              href="#"
              @click.prevent="onClose(props)">&times;</a>
            <p
              v-if="props.item.title"
              class="notify-msg text-break">
              <strong v-html="$sanitize(props.item.title)" />
            </p>
            <p
              class="notify-msg text-break"
              v-html="$sanitize(props.item.text)" />
            <span
              v-if="props.item.data && props.item.data.list"
              id="list-holder"
              :title="getTitle(props.item.data.list)">
              <strong
                v-for="(item, index) in props.item.data.list.slice(0, 5)"
                :key="index">
                {{ props.item.data.format(item) }}
                <span v-if="props.item.data.list.length > 5 && index === 4">...</span>
              </strong>
            </span>
            <p
              v-if="props.item.data && props.item.data.buttons"
              class="notify-btns">
              <qx-button
                v-for="(button, key) in props.item.data.buttons"
                :id="`${key}-notification`"
                :key="key"
                :state="button.state"
                :title="button.title"
                :action="
                  () => {
                    button.action(props);
                  }
                " />
            </p>
          </div>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
import QxButton from '@/elements/button.vue';
import EventBus from '@/elements/eventBus.js';

export default {
  name: 'Notify',
  components: { QxButton },
  data() {
    return {
      localTimers: [],
    };
  },
  created() {
    this.$root.notifications = this;
  },
  mounted() {
    EventBus.$on('closeNotification', (id) => {
      const notification = this.$refs[id];
      notification && notification.getElementsByClassName('close')[0].click();
    });
  },
  methods: {
    getIcon(type) {
      const icons = {
        notice: 'icon-info',
        success: 'icon-success',
        warning: 'icon-warning',
        error: 'icon-error',
      };
      return icons[type];
    },
    getTitle(list = []) {
      return list.map((item) => item.email || item).join(', ');
    },
    onClose(props) {
      props.item.data && typeof props.item.data.onClose === 'function' && props.item.data.onClose();
      props.close();
    },
    checkIfOpenById(id) {
      return Boolean(this.$refs[id]);
    },
    onMouseEnter({ item }) {
      clearTimeout(item.timer);
      clearTimeout(this.localTimers[item.id]);
      this.localTimers[item.id] = null;
    },
    onMouseLeave(props) {
      const item = props.item;
      // item.timer is falsy for notification that shouldn't disappear without any actions
      if (item.timer) {
        this.localTimers[item.id] = setTimeout(() => {
          this.onClose(props);
          this.localTimers[item.id] = null;
        }, 1000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// copied from node_modules/vue-notification/src/Notifications.vue to avoid inline style
@import '@/assets/scss/_notifications.scss';

.notify {
  width: 500px;
  z-index: 15007;
  margin: 10px 10px 0 0;
  background-color: #f5f6f5;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  .notify-icon-box {
    position: absolute;
    height: 100%;
    left: 0;
    float: left;
    .icon-notify {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      transform: translateY(-50%);
      font-family: 'quatrix-icomoon';
      display: block;
      background-color: #fff;
      margin: 0 14px;
      padding: 10px;
      border-radius: 50%;
      font-size: 18px;
      line-height: 22px;
      width: 42px;
      height: 42px;
      text-align: center;
    }
    &.icon-success {
      background-color: #40b93e;
      .icon-notify:before {
        color: #40b93e;
        content: '\e700';
      }
    }
    &.icon-info {
      background-color: #35b3e4;
      .icon-notify:before {
        color: #35b3e4;
        content: '\e701';
      }
    }
    &.icon-error {
      background-color: #b9223f;
      .icon-notify:before {
        color: #b9223f;
        content: '\e702';
      }
    }
    &.icon-warning {
      background-color: #fca628;
      .icon-notify:before {
        color: #fca628;
        content: '\e703';
      }
    }
  }
  .msg-box {
    width: 430px;
    min-height: 70px;
    float: right;
    padding: 10px;
    .notify-btns {
      @extend %dspFlex;
      @include flex-direction(row-reverse);
      button {
        flex-shrink: 1;
        min-width: 30px;
        margin-left: 6px;
      }
      :deep {
        .v-btn__content {
          span {
            white-space: normal;
          }
        }
      }
    }
    #list-holder {
      display: block;
      text-align: left;
      margin: 15px 0;
      strong {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .notify-msg {
      max-height: 80vh;
      overflow: auto;
    }
  }
  .close {
    color: $black;
    text-decoration: none;
    line-height: 0.6;
    position: absolute;
    right: 10px;
    top: 2px;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 $white;
    opacity: 0.5;
    &:hover {
      opacity: 0.75;
    }
  }
}
</style>
