const icons = [
  {
    type: 'img',
    formats: [
      'tif',
      'tiff',
      'gif',
      'jpeg',
      'jpg',
      'pjpeg',
      'jif',
      'jfif',
      'jp2',
      'jpx',
      'j2k',
      'j2c',
      'fpx',
      'pcd',
      'png',
      'raw',
    ],
    icon: 'file-list-icon jpg',
  },
  {
    type: 'psd',
    formats: ['psd'],
    icon: 'file-list-icon psd',
  },
  {
    type: 'svg',
    formats: ['svg'],
    icon: 'file-list-icon svg',
  },
  {
    type: 'doc',
    formats: ['doc', 'dot', 'docx', 'docm', 'dotx', 'dotm', 'sldx', 'sldm', 'odt'],
    icon: 'file-list-icon word',
  },
  {
    type: 'csv',
    formats: ['csv'],
    icon: 'file-list-icon csv',
  },
  {
    type: 'exe',
    formats: ['exe'],
    icon: 'file-list-icon exe',
  },
  {
    type: 'txt',
    formats: ['txt', 'rtf'],
    icon: 'file-list-icon txt',
  },
  {
    type: 'web',
    formats: ['html', 'py', 'css', 'cpp', 'rb'],
    icon: 'file-list-icon html',
  },
  {
    type: 'cod',
    formats: ['js'],
    icon: 'file-list-icon js',
  },
  {
    type: 'sql',
    formats: ['sql'],
    icon: 'file-list-icon sql',
  },
  {
    type: 'tbl',
    formats: ['xls', 'xlt', 'xlm', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlsb', 'ods'],
    icon: 'file-list-icon xls',
  },
  {
    type: 'xml',
    formats: ['xml'],
    icon: 'file-list-icon xml',
  },
  {
    type: 'pdf',
    formats: ['pdf'],
    icon: 'file-list-icon pdf',
  },
  {
    type: 'prs',
    formats: ['ppo', 'pptm', 'potx', 'potm', 'ppsx', 'ppt', 'pot', 'pps', 'pptx', 'ppsm'],
    icon: 'file-list-icon ppt',
  },
  {
    type: 'arh',
    formats: ['zip', '7zip', 'gz', 'tgz', 'tar', 'rar'],
    icon: 'file-list-icon zip',
  },
  {
    type: 'iso',
    formats: ['iso'],
    icon: 'file-list-icon iso',
  },
  {
    type: 'vid',
    formats: [
      'flv',
      'avi',
      'mov',
      'mp4',
      'mpg',
      'wmv',
      '3gp',
      'asf',
      'rm',
      'swf',
      'mkv',
      'mpeg',
      'ogg',
      'qt',
      'moov',
      'webm',
    ],
    icon: 'file-list-icon avi',
  },
  {
    type: 'mus',
    formats: ['alac', 'flac', 'mp3', 'ogg', 'wav', 'wma'],
    icon: 'file-list-icon mp3',
  },
  {
    type: 'back',
    icon: 'qx-icon icon-back',
  },
  {
    type: 'unk',
    icon: 'file-list-icon other',
  },
  {
    type: 'dir',
    icon: 'file-list-icon simple-folder',
  },
  {
    type: 'folderOpen',
    icon: 'file-list-icon simple-folder',
  },
  {
    type: 'incoming',
    icon: 'file-list-icon inbox',
  },
  {
    type: 'outgoing',
    icon: 'file-list-icon outbox',
  },
  {
    type: 'projects',
    icon: 'file-list-icon project-shared-with-me',
  },
  {
    type: 'homes',
    icon: 'file-list-icon user-homes',
  },
  {
    type: 'sharedFolder',
    icon: 'file-list-icon project-folder',
  },
  {
    type: 'trash',
    icon: 'file-list-icon trash',
  },
  {
    type: 'transparent',
    icon: 'file-list-icon other',
  },
  {
    type: 'encrypted',
    formats: ['enc'],
    icon: 'file-list-icon encrypted',
  },
  {
    type: 'homeFolder',
    icon: 'file-list-icon user-homes-folder',
  },
];

export const getByExtension = (format) => {
  const extension = icons.find((icon) => icon.formats && ~icon.formats.indexOf(format));
  return extension || getByType('unk');
};

export const getByType = (type) => icons.find((icon) => icon.type === type);
