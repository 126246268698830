import * as moment from 'moment';
import { MOMENT_FORMATS } from '@/utils/cons.js';
import { getGroupCode } from '@/elements/utils.js';
import {
  createContact,
  getRecipients,
  getContacts,
  getContactMetadata,
  updateContact,
  deleteContacts,
  getUsersContactList,
} from '@/api';

const getInitialState = () => ({
  contactList: [],
  contactCollection: new Map(),
  recipientList: [],
  recipientCollection: new Map(),
  usersContactList: [],
  contactsForShare: [],
});

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    parse: (state, getters, rootState, rootGetters) => (contact) => {
      if (contact.created !== undefined) {
        contact.createdUnix = contact.created;
        contact.created = moment.unix(contact.created).format(MOMENT_FORMATS.DATE);
      }
      contact.groups = contact.groups || [];
      if (!contact.groups.length && contact.type === 'C') {
        contact.groups.push(rootGetters['groups/getPredefinedModel']);
      }
      if (contact.groups && contact.groups.length) {
        contact.groupsId = contact.groups[0].id;
        contact.groupCode = getGroupCode(contact.groups[0]);
        contact.groups.forEach((group) => {
          group.name = rootGetters['groups/getGroupName'](group.metadata?.translation_id) || group.name;
        });
      }
      contact.isActive = contact.status === 'A';
      // Checked statuses in table
      contact.isDisabledState = false;
      contact.isCheckedState = false;
      contact.isActiveState = false;
      return contact;
    },
    getAttributes: () => (attr) => {
      return {
        ...(attr.name && { name: attr.name }),
        ...(attr.email && { email: attr.email.toLowerCase() }),
        ...(attr.hasOwnProperty('notes') && { notes: attr.notes }),
        ...(typeof attr.groupsId !== 'undefined' && { groups: attr.groupsId ? [attr.groupsId] : [] }),
        ...{ personal: attr.personal || !attr.groupsId || false },
      };
    },
    getContactById: (state) => (id) => {
      return state.contactCollection.get(id);
    },
    getTmpModel: (state) => {
      return {
        id: null,
        name: '',
        email: '',
        notes: '',
        has_key: false,
        isActive: true,
        groups: [],
        groupsId: null,
        groupCode: '',
        readonly: false,
        owner_id: null,
        type: 'C',
        // Checked statuses in table
        isDisabledState: false,
        isCheckedState: false,
        isActiveState: false,
      };
    },
    getRecipientByEmail: (state) => (email) => {
      return state.recipientCollection.get(email.toLowerCase());
    },
  },
  mutations: {
    setContactList(state, contacts) {
      state.contactCollection = new Map(contacts.map((contact) => [contact.id, contact]));
      state.contactList = Array.from(state.contactCollection.values());
    },
    setRecipientList(state, recipients) {
      state.recipientList = recipients;
      state.recipientCollection = new Map(recipients.map((recipient) => [recipient.email.toLowerCase(), recipient]));
      state.recipientList = Array.from(state.recipientCollection.values());
    },
    setContactsForShare(state, contacts) {
      state.contactsForShare = contacts;
    },
    createContact(state, contact) {
      state.contactCollection.set(contact.id, contact);
      state.recipientCollection.set(contact.email, contact);
      state.recipientList = Array.from(state.recipientCollection.values());
      state.contactList = Array.from(state.contactCollection.values());
    },
    updateContact(state, contact) {
      const foundContact = state.contactCollection.get(contact.id);
      if (!foundContact) {
        return;
      }
      const { isActiveState, isCheckedState, isDisabledState } = foundContact;
      const updatedContact = { ...contact, isCheckedState, isActiveState, isDisabledState };
      state.contactCollection.set(updatedContact.id, updatedContact);
      state.contactList = Array.from(state.contactCollection.values());
    },
    deleteContacts(state, contactIds) {
      contactIds.forEach((id) => {
        state.contactCollection.delete(id);
      });
      state.contactList = Array.from(state.contactCollection.values());
    },
    setUsersContactList(state, list) {
      state.usersContactList = list;
    },
    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    async getContacts({ getters, commit }) {
      const { data } = await getContacts();
      let contacts = (data || []).map((contact) => getters.parse(contact));
      commit('setContactList', contacts);
      return data;
    },
    async getContactMetadata({ getters, commit }, id) {
      const { data } = await getContactMetadata(id);
      const contact = getters.parse(data);
      commit('updateContact', contact);
      return data;
    },
    async createContact({ getters, commit }, payload) {
      const { data } = await createContact(getters.getAttributes(payload));
      const contact = getters.parse(data);
      commit('createContact', contact);
      return data;
    },
    async updateContact({ getters, commit }, { id, payload }) {
      const { data } = await updateContact(id, getters.getAttributes(payload));
      const contact = getters.parse(data);
      commit('updateContact', contact);
      return contact;
    },
    async deleteContacts({ commit, getters }, ids) {
      const { data } = await deleteContacts(ids);
      const response = (data || []).map((item) => getters.getContactById(item.id));
      const contactsIds = data.map((item) => item.id);
      commit('deleteContacts', contactsIds);
      return response;
    },
    async getRecipients({ getters, commit }) {
      const { data } = await getRecipients();
      let recipients = (data || []).map((recipient) => getters.parse(recipient));
      commit('setRecipientList', recipients);
      return data;
    },
    async getUsersContactList({ commit }) {
      const { data } = await getUsersContactList();
      commit('setUsersContactList', data || []);
    },
  },
};
